export const voucherStatus = [
  { text: "roboczy", textLong: "roboczy", className: "phase0" }, ///0
  {
    text: "do podania",
    textLong: "czeka na odbiór",
    className: "phase1",
  }, //1
  { text: "w hotelu", textLong: "dostępny", className: "phase2" }, //2
  { text: "wydany", textLong: "wydany klientowi", className: "phase3" }, //3
  { text: "użyty", textLong: "wykorzystany", className: "phase4" }, //4
];
