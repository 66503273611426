import { Form, useForm } from "react-hook-form";
import { URL } from "../paths";
import { useContext, useEffect } from "react";
import { ViewContext } from "../contexts/ViewContext";

export default function AddEntry({
  employee,
  setUpdates,
}: {
  employee: any;
  setUpdates: Function;
}) {
  const { register, control, setValue } = useForm();
  const { setModal } = useContext(ViewContext);

  useEffect(() => {
    const currentDate = new Date();
    const nextDate = new Date();
    nextDate.setFullYear(nextDate.getFullYear() + 5);
    nextDate.setDate(nextDate.getDate() - 1);
    setValue("employee_id", employee.id);
    setValue("date", currentDate.toISOString().slice(0, 10));
    setValue("date_next", nextDate.toISOString().slice(0, 10));
    setValue("type", "1");
    setValue("next_type", "1");
  }, []);

  return (
    <div className="add-employee-entry">
      <Form
        action={`${URL}backend/inserts/set-medicals.php`}
        headers={{ "Content-Type": "application/json" }}
        onSuccess={async ({ response }: { response: any }) => {
          //   const result = await response;
          // console.log(response.json());
          setUpdates((prev: number) => prev + 1);
          setModal((prev: any) => ({ ...prev, show: false }));
        }}
        onError={() => {
          alert("Wystąpił problem.");
        }}
        control={control}
      >
        <div className="two-columns">
          <div className="field">
            <label htmlFor="date">Data badania</label>
            <input id="date" type="date" {...register("date")} />
          </div>
          <div className="field">
            <label htmlFor="type">Rodzaj</label>
            <select id="type" {...register("type")}>
              <option value="0">wstępne</option>
              <option value="1">okresowe</option>
              <option value="2">kontrolne</option>
            </select>
          </div>
        </div>
        <div className="two-columns">
          <div className="field">
            <label htmlFor="date_next">Data kolejnego badania</label>
            <input id="date_next" type="date" {...register("date_next")} />
          </div>
          <div className="field">
            <label htmlFor="next_type">Rodzaj</label>
            <select id="next_type" {...register("next_type")}>
              <option value="0">wstępne</option>
              <option value="1">okresowe</option>
              <option value="2">kontrolne</option>
            </select>
          </div>
        </div>
        <div className="field">
          <label htmlFor="comment">Uwagi</label>
          <textarea id="comment" {...register("comment")} />
        </div>
        <div>
          <button type="submit">Dodaj</button>
        </div>
      </Form>
    </div>
  );
}
