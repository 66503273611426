import { useState } from "react";
import useGeneric from "./dataFetch/useGeneric";
import Loading from "./common/Loading";
import Markdown from "react-markdown";

export default function AskGPT() {
  const [input, setInput] = useState<any>("");
  const [update, setUpdate] = useState(0);
  const [prompt, setPrompt] = useState<any>();
  const response = useGeneric({
    name: prompt && "ask-gpt",
    query: {
      content: prompt,
    },
    update: update,
  });

  return (
    <div>
      <div className="gpt-query">
        <h1>AI</h1>
        <input value={input} onChange={(e) => setInput(e.target.value)} />
        <button
          onClick={() => {
            setPrompt(input);
            setUpdate((prev: number) => prev + 1);
          }}
        >
          Zapytaj AI
        </button>
      </div>

      <div className="gpt-response">
        {prompt && response?.loading === true ? (
          <Loading />
        ) : (
          <Markdown>{response?.text}</Markdown>
        )}
      </div>
      {/* {JSON.stringify(response)} */}
    </div>
  );
}
