import AddMissingNew from "./AddMissingNew";
import AttEvent from "./AttEvent";

export default function AttEvents({
  events,
  t,
  date,
  setUpdate,
  location,
  selectedPerson,
  employeeData,
}: {
  events: any[];
  t: number;
  date: Date;
  setUpdate: Function;
  location: string;
  selectedPerson: string | false;
  employeeData: any;
}) {
  // const eventsPrep = events?.sort((a:any,b:any) => new a.t1)

  return (
    <div className={"attendanceEvents" + (t === 1 ? " in" : " out")}>
      {events && events.length ? (
        events?.map((event: any) => (
          <AttEvent
            event={event}
            t={t}
            date={date}
            location={location}
            setUpdate={setUpdate}
          />
        ))
      ) : (
        <div className="addMissing">
          <AddMissingNew
            event={{ cardname: selectedPerson, employeeID: employeeData?.id }}
            date={date}
            defaultEvent={t === 1 ? 0 : 1}
            setUpdate={setUpdate}
            location={location}
          />
        </div>
      )}
    </div>
  );
}
