import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import ChangeRow from "../forms_new/ChangeRow";
import userIcon from "../../images/icons/pencil.png";
import { isObject } from "../functions/tools";
import useCompare from "../forms_new/useCompare";
import useSuperFlatSchema from "./useSuperFlatSchema";

export default function EventHistoryNew({
  id,
  data,
  schema,
  options,
}: {
  id: number;
  data: any;
  schema: any;
  options?: any;
}) {
  const [selectedIndex, setSelectedIndex] = useState<any>();

  const superFlatSchema = useSuperFlatSchema(schema);

  const fetch = useGeneric({
    name: "event-calendar-archive",
    limit: 100,
    query: {
      archive: true,
      id: id,
    },
  });

  const all: any = !fetch.loading && fetch.rows ? fetch.rows : [];

  const { original, proposed } = useCompare(
    superFlatSchema,
    selectedIndex,
    all
  );
  
  // console.log(
  //   "schema",
  //   superFlatSchema,
  //   "proposed",
  //   proposed,
  //   "original",
  //   original
  // );

  const currentEntry = all?.[selectedIndex];

  return selectedIndex !== undefined && all ? (
    <div className="calendar-history" style={{ maxHeight: "unset" }}>
      <div className="calendar-history-controls">
        <button
          style={
            selectedIndex === all.length - 1 ? { opacity: 0.3 } : { opacity: 1 }
          }
          onClick={() =>
            setSelectedIndex((prev: number) =>
              prev < all.length - 1 ? prev + 1 : prev
            )
          }
        >
          Poprzednia zmiana
        </button>
        <button onClick={() => setSelectedIndex(undefined)}>Powrót</button>
        <button
          style={selectedIndex === 0 ? { opacity: 0.3 } : { opacity: 1 }}
          onClick={() =>
            setSelectedIndex((prev: number) => (prev > 0 ? prev - 1 : prev))
          }
        >
          Następna zmiana
        </button>
      </div>
      <div className="history-item-summary">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            Zmiana {all.length - selectedIndex} z {all.length}{" "}
          </div>
          <div>{prettyDate(currentEntry?.edited_time)}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "12px", maxWidth: "80%" }}>
            {currentEntry?.reason}
          </div>
          <div className="person">
            <img src={userIcon} style={{ width: "14px" }} />{" "}
            {currentEntry?.edited_string}
          </div>
        </div>
      </div>
      <table className="data-table form-changes">
        <tbody>
          {superFlatSchema.map((prop: any) =>
            original && proposed ? (
              <ChangeRow
                key={prop?.name}
                flatSchema={superFlatSchema}
                prop={prop?.name}
                original={original}
                proposed={proposed}
                options={options}
              />
            ) : (
              <></>
            )
          )}
        </tbody>
      </table>
    </div>
  ) : all ? (
    <div className="calendar-history history-list">
      {all?.map((item: any, index: number) => (
        <div key={item.archive_id} className="history-item-short">
          <div className="history-item-info">
            <div className="time">
              <div className="history-item-index">{all.length - index}</div>
              {item?.edited_time
                ? prettyDate(item?.edited_time)
                : prettyDate(item?.created_time)}
            </div>
          </div>
          <div className="person">
            {item?.edited_string ? item?.edited_string : item?.created_string}
          </div>
          <div className="reason">{item?.reason}</div>
          <div>
            <button onClick={() => setSelectedIndex(index)}>Zobacz</button>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <>-</>
  );
}
