import { CalendarContext } from "../contexts/CalendarContext";
import insertGeneric from "../dataFetch/insertGeneric";
import useGeneric from "../dataFetch/useGeneric";
import Form from "../forms_new/Form";
import EventDataProvider from "./EventDataProvider";
import EventHistory from "./EventHistory";
import EventHistoryNew from "./EventHistoryNew";
import { eventForm } from "./addEventFormUpdated";
import { calendarEntry } from "./calendarEntry";

export function processDateSpecificFields(data: any) {
  const dateData = data?.dates ? JSON.parse(data.dates) : undefined;
  // let scheduleData = data?.schedules ? JSON.parse(data.schedules) : undefined;
  let scheduleData: any = [];

  // process dated items and add to dates JSON
  if (dateData) {
    Object.keys(data).forEach((name: any) => {
      const dated = name.match(/(.*)%(.*)%$/);
      let date = "";
      let propName = "";
      let propValue: any = "";
      if (dated) {
        propName = dated[1]; //meals, drinks...
        date = dated[2];
        propValue = data[name as keyof typeof data];
        delete data[name];
      }
      const index = dateData.findIndex((d: any) => d.date === date);
      if (index !== -1) {
        dateData[index] = { ...dateData[index], [propName]: propValue };
        if (propName === "schedule") {
          scheduleData.push(JSON.parse(propValue));
        }
      }
    });
  }

  //process schedule items and add to schedules JSON
  Object.keys(data).forEach((name: any) => {
    const guidCoded = name.match(/(.*)@(.*)@/);

    let date = "";
    let propName = "";
    let itemID = 0;
    let propValue: any = "";
    if (guidCoded) {
      propName = guidCoded[1]; //meals, drinks...

      itemID = Number(guidCoded[2]); //id
      propValue = data[name as keyof typeof data];
      // console.log(name, propName, itemID, propValue);
      // console.log(guidCoded, propName, propValue);
      delete data[name];

      const flatValue = Object.keys(propValue)
        .map((k: any) => propValue[k])
        .flat();
      if (scheduleData) {
        scheduleData = scheduleData.map((sch: any) =>
          sch.map((s: any) => {
            if (s?.id == itemID || s?.guid == itemID) {
              return {
                ...s,
                [propName]: JSON.stringify(flatValue),
              };
            } else {
              return s;
            }
          })
        );
      }
    }
  });

  data.dates = JSON.stringify(dateData);
  // console.log('sch-data',scheduleData);
  data.schedules = JSON.stringify(scheduleData);

  const weddingPropsArray =
    data.event_type === 4
      ? Object.keys(data).filter((name: any) => name.match(/^wedding&/))
      : [];
  const weddingShortProps = weddingPropsArray?.map((name: any) =>
    name.replace(/^wedding&(.*)/, "$1")
  );

  data.wedding = {};
  weddingShortProps.forEach((k: any, index: number) => {
    const ko = weddingPropsArray[index];
    if (data && data[ko]) {
      if (data?.[ko] === "undefined") {
        data.wedding[k] = undefined;
      } else {
        data.wedding[k] = data?.[ko];
      }
    }
  });
  console.log(data.wedding);

  const dataF: any = {};

  Object.keys(data)?.forEach((key: string) => {
    if (key.match(/^wedding&/)) {
      //  dataF[key] = data[key];
    } else {
      dataF[key] = data[key];
    }
  });

  return dataF;
}

export default function openEditor(
  data: any = undefined,
  date: any,
  { modal, calendar }: { modal: any; calendar: any },
  auth?: any,
  setUpdateCustom?: Function
) {
  let heading = "Nowe wydarzenie";
  if (data !== undefined) {
    heading = "Edytuj wydarzenie";
  }

  calendar.setFormShow((prev: boolean | number) =>
    typeof prev === "boolean" ? 0 : prev + 1
  );
  modal.setModal({
    show: true,
    heading: heading,
    style: { width: "1050px", minHeight: "90vh" },
    updater: calendar.setUpdate,
    content: (
      <>
        <EventDataProvider
          data={data}
          auth={auth}
          process={processDateSpecificFields}
          date={date}
          modal={modal}
          calendar={calendar}
          setUpdateCustom={setUpdateCustom}
        />
        {/*        
            <div style={{ padding: "18px" }}>Agenda - w przygotowaniu</div>
            <div style={{ padding: "18px" }}>Obsługa - w przygotowaniu</div>
      */}
      </>
    ),
  });
}
