import { useFormContext } from "react-hook-form";
import { formField } from "../formContentType";

export default function Input({
  schema,
  hidden,
  visible,
  setManualChanges,
}: {
  schema: formField;
  hidden: any;
  visible: Boolean;
  setManualChanges: Function;
}) {
  const {
    register,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();

  const {
    name,
    inputType,
    min,
    max,
    step,
    maxLength,
    style,
    minLength,
    pattern,
    validate,
    getOptionsFrom,
    required,
    optionsList,
    initialValue,
    disabled,
    readOnly
  } = schema;
  // console.log(name, schema)
  return (
    <input
      className={
        (errors?.[name] ? "error" : "") +
        (touchedFields?.[name] ? " touched" : "")
      }
      type={inputType}
      title={errors[name]?.message?.toString()}
      min={min?.value ? min.value : 0}
      max={max?.value}
      step={step}
      maxLength={maxLength?.value}
      style={style}
      autoComplete={
        schema?.autoComplete === undefined
          ? "on"
          : schema?.autoComplete
          ? "on"
          : "off"
      }
      readOnly={readOnly ? true : false}
      id={name}
      {...register(name, {
        required: visible ? required : undefined,
        min: min ? min : 0,
        max: max,
        minLength: minLength,
        maxLength: maxLength,
        disabled: disabled,
        pattern: pattern,
        validate: validate,
        value: schema?.subDefaults?.["_" + name?.split("%")?.[0]],
        onChange: () => setManualChanges((prev: number) => prev + 1),
      })}
    />
  );
}
