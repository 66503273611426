import { useFormContext } from "react-hook-form";
import { formField } from "../formContentType";
import FieldSet from "./FieldSet";
import { useContext, useEffect, useState } from "react";
import SubTabFields from "./SubTabFields";
import { FormDataContext } from "../Form";
import SubTab from "./SubTab";

export default function SubTabs({ schema }: { schema: formField }) {
  const { getValues, setValue } = useFormContext();
  const { subTab: subItem, setSubTab: setSubItem } =
    useContext(FormDataContext);

  const {
    data,
    flatSchema: fullSchema,
    setCustomFieldData,
  } = useContext(FormDataContext);
  const { subTabs: settings } = schema;
  const jsonTabs = settings?.source ? getValues(settings?.source?.[0]) : [];

  const tabs = jsonTabs && JSON.parse(jsonTabs);

  //   const tabs = settings?.source && data ? data[settings.source?.[0] as keyof typeof data] : [];
  const propName = settings?.source?.[1] || 0;
  const transform = settings?.transform;
  // console.log(tabs, tabs?.[0]?.[propName]);
  //const [subItem, setSubItem] = useState(tabs?.[0]?.[propName]);
  //   console.log(tabs);

  //   useEffect(() => {
  //     if (!subItem && setSubItem && tabs && tabs.length && tabs[0][propName]) {
  //       setSubItem(tabs[0][propName]);
  //     }
  //   }, [subItem]);

  const sortedTabs =
    tabs &&
    tabs.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );

  return transform && propName && setSubItem ? (
    <div className="sub-tab-wrapper">
      <div className="sub-tabs">
        {sortedTabs?.map((t: any, index: number) => (
          <SubTab
            key={t?.guid || index}
            subItem={subItem}
            t={t}
            propName={propName}
            index={index}
            transform={transform}
            setSubItem={setSubItem}
          />
        ))}
        {schema.readOnly ? (
          <></>
        ) : (
          <div
            style={{ minWidth: "unset" }}
            onClick={() => {
              const proceed = window.confirm("Dodać kolejny dzień?");
              if (!proceed) return;
              const which: any = schema?.subTabs?.source[0];
              const affectedItem = fullSchema.find(
                (f: any) => f.name === which
              );
              const whichValues = JSON.parse(getValues(which));
              setValue(
                which,
                JSON.stringify([
                  ...whichValues,
                  ...[affectedItem.multiAddProps.add[0](whichValues)],
                ])
              );
              setCustomFieldData((prev: any) => ({
                ...prev,
                [which]: { ...prev[which], updates: prev[which].updates + 1 },
              }));
              //console.log(affectedItem.multiAddProps.add[0](whichValues));
            }}
          >
            +
          </div>
        )}
      </div>
      <div>
        {settings && settings.fields ? (
          tabs?.map((t: any, index: number) => (
            <SubTabFields
              index={index}
              key={t?.[propName] + index + t?.guid} ///t?.guid || index}
              keyPass={t?.[propName] + index + t?.guid}
              item={t}
              subItem={subItem}
              setSubItem={setSubItem}
              propName={propName}
              settings={settings}
              topSchema={schema}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
