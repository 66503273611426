import { useState } from "react";
import VoucherListGroupView from "./VoucherListGroupView";
import { voucherStatus } from "./voucherStatus";
import Info from "../common/Info";
import Loading from "../common/Loading";

export default function VoucherListGroups({
  items,
  heading = false,
  loading,
  amount,
  paid,
  auth,
  setUpdate,
  currentItem,
  setCurrentItem,
}: {
  items: any;
  heading?: Boolean;
  loading: Boolean;
  amount?: number;
  paid?: number;
  auth: number;
  setUpdate: Function;
  currentItem: any;
  setCurrentItem: Function;
}) {
  const [options, setOptions] = useState<any>();
  let itemsShow = items;
  if (amount !== undefined && paid !== undefined) {
    itemsShow = items?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid
    );
  }
  itemsShow?.sort((a: any, b: any) => Number(a.order) - Number(b.order));

  const groups: any = [];

  itemsShow.forEach((i: any, index: number, arr: any[]) => {
    const prev = arr[index - 1];
    if (!prev || (prev && prev.group_id !== i.group_id)) {
      groups.push({
        id: i.group_id,
        items: itemsShow.filter((v: any) => v?.group_id === i?.group_id),
      });
    }
  });

  const status = voucherStatus;

  return (
    <div onClick={() => options && setOptions(undefined)}>
      {heading ? (
        <div className="category-selected-heading">
          Vouchery{" "}
          <strong>
            {paid !== undefined && (paid ? "PŁATNE" : "GRATISOWE")}
          </strong>{" "}
          {amount !== undefined ? (
            <span style={{ fontSize: "110%" }}>{amount} zł</span>
          ) : (
            <>wszystkie</>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="view-groups">
        {groups && groups?.length ? (
          groups?.map((g: any, index: number) => (
            <VoucherListGroupView
              key={g.id}
              group={g}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              options={options}
              setOptions={setOptions}
              setUpdate={setUpdate}
            />
          ))
        ) : loading ? (
          <Loading />
        ) : (
          <Info type="Ups! Nic nie znaleziono." />
        )}
      </div>
    </div>
  );
}
