import path from "path";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AttendancePairs from "./attendance/AttendancePairs";
import AttendanceReports from "./attendance/AttendanceReports";
import AttendanceSummary from "./attendance/AttendanceSummary";
import AttendanceTable from "./attendance/AttendanceTable";
import Employees from "./attendance/Employees";
import HolidayReports from "./attendance/HolidayReports";
import HolidayRequests from "./attendance/HolidayRequests";
import HolidaysTable from "./attendance/HolidaysTable";
import preAdjust from "./attendance/preAdjust";
import process from "./attendance/process";
import SelectHolidayType from "./attendance/SelectHolidayType";
import generateCalendarData from "./calendar/generateCalendarData";
import CalendarControls from "./common/CalendarControls";
import Info from "./common/Info";
import Loading from "./common/Loading";
import { UserContext } from "./contexts/UserContext";
import { ViewContext } from "./contexts/ViewContext";
import setActive from "./dataFetch/setActive";
import useGeneric from "./dataFetch/useGeneric";
import minutesToJSX, { minutesToString } from "./functions/minutesToJSX";
import { URL } from "./paths";
import ErrorItems from "./tables/ErrorItems";
import Table from "./tables/Table";
import attendanceView from "./tables/views/attendanceView";
import AnnualReports from "./attendance/AnnualReports";
import NewAttendanceTable from "./attendance/NewAttendanceTable";
import { calculateWorkingDays } from "./functions/dates";
import AttendanceSumYear from "./attendance/AttendanceSumYear";
import AttendanceSumsYear from "./attendance/AttendanceSumsYear";

export default function Attendance() {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("attendance");
  const browseLocation = useLocation();
  const navigate = useNavigate();
  const pathURL = browseLocation.pathname.split("/").at(-1);
  const seeRequest =
    browseLocation.pathname.split("/").at(-2) === "requests"
      ? browseLocation.pathname.split("/").at(-1)
      : undefined;
  //console.log(seeRequest);
  const { permissions, employeeID } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);
  const [updatedFromDevice, setUpdatedFromDevice] = useState(false);
  const [deviceUpdates, setDeviceUpdates] = useState(0);
  const [update, setUpdate] = useState(0);
  const [smallUpdate, setSmallUpdate] = useState(0);
  const [holidaySumType, setHolidaySumType] = useState(0);
  const [smallList, setSmallList] = useState(false);
  const [view, setView] = useState<"calendar" | "list">("calendar");
  const [date, setDate] = useState({
    month: new Date().getMonth(),
    week: 1,
    year: new Date().getFullYear(),
  });

  /*  console.log(
    "working-days",
    calculateWorkingDays(2024, {
      from: new Date(2024, 0, 1),
      to: new Date(2024, 0, 14),
    })
  );
*/
  const [deviceUpdateBoxHide, setDeviceUpdateBoxHide] = useState(false);
  const [serverStatus, setServerStatus] = useState<
    false | { hotel: any; office: any; success: Boolean }
  >(false);

  //Set dates to load pairs from this month, but also the last two days of the previous one and the first two days of the next one
  const timestampFromDate = new Date(date.year, date.month);
  timestampFromDate.setHours(0, 0, 0, 0);
  const timestampFrom = timestampFromDate.setDate(-1) / 1000;

  const timestampToDate = new Date(date.year, date.month);
  timestampToDate.setMonth(timestampToDate.getMonth() + 1);
  timestampToDate.setHours(1, 0, 0, 0);
  const timestampTo = timestampToDate.setDate(2) / 1000;

  const current = new Date(date.year, date.month);

  const viewContext = useContext(ViewContext);

  const [selectedPerson, setSelectedPerson] = useState<string | false>(false);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState<
    any | undefined
  >(undefined);

  const [location, setLocation] = useState(() =>
    pathURL === "biuro" || pathURL === "hotel" ? pathURL : "hotel"
  );

  const [employeeViewMode, setEmployeeViewMode] = useState<
    | "present"
    | "sums"
    | "list"
    | "holidays"
    | "holidayRequests"
    | "holidayReports"
    | "attendanceReports"
    | "annualReports"
  >(seeRequest ? "holidayRequests" : "present");

  const cardsAndEmployees = useGeneric({
    name: ["cards", "employees", "employeeGroups"],
    limit: 2000,
    reload: true,
    //update: update,
    query: {
      active: true,
      location: location,
      month: date.month + 1,
      year: date.year,
      //employeeID: p === 1 ? employeeID : undefined
    },
    secondsToReload: 15,
  });

  const holidayTypes = useGeneric({
    name: "holidayTypes",
    limit: 2000,
  })?.rows;

  const lastCheckIns = useGeneric({
    name: "attendanceLatest",
    limit: 150,
    update: update,
    query: {
      location: location,
    },
    reload: true,
    secondsToReload: 15,
  });

  const lastCheckInRows = !lastCheckIns.loading ? lastCheckIns.rows : [];

  const attendanceSums: any = useGeneric({
    name: "attendanceSums",
    limit: 10000,
    query: {
      month: date.month + 1,
      year: date.year,
      location: location === "biuro" ? "1" : "0",
    },
    reload: true,
    update: update + smallUpdate,
  });

  const attendancePairs: any = useGeneric({
    name: "attendancePairs",
    limit: 10000,
    query: {
      //cardperson: selectedPerson,
      active: true,
      timestampFrom: timestampFrom,
      timestampTo: timestampTo,
      location: location,
    },
    loading: true,
    update: update,
    reload: true,
    secondsToReload: 30,
    additional: date,
  });

  const holidaysRaw: any = useGeneric({
    name: "holidays",
    limit: 9000,
    update: update,
    reload: true,
    secondsToReload: 30,
    additional: smallUpdate,
  });

  const holidaysSuggestedRaw: any = useGeneric({
    name: "holidaysSuggested",
    limit: 9000,
    update: update,
    reload: true,
    secondsToReload: 30,
    query: { year: date.year },
    additional: smallUpdate,
  });

  const holidayCountRaw = useGeneric({
    name: "holidayCount",
    limit: 9999999,
    update: smallUpdate + update,
    reload: true,
    secondsToReload: 30,
  });
  const holidayCount = holidayCountRaw.loading
    ? undefined
    : holidayCountRaw.rows;

  const holidays = !holidaysRaw.loading
    ? holidaysRaw.rows.filter((h: any) =>
        selectedEmployeeData ? h.employeeID === selectedEmployeeData.id : false
      )
    : undefined;

  const holidaysSuggested = !holidaysSuggestedRaw.loading
    ? holidaysSuggestedRaw.rows.filter((h: any) =>
        selectedEmployeeData ? h.employeeID === selectedEmployeeData.id : false
      )
    : undefined;
  //console.log(holidaysSuggested)
  //const attendancePairAdjusted: any = preAdjust(attendancePairs, date.month, date.year);
  const attendanceRows = attendancePairs.rows;
  const dataByPerson: any = attendancePairs.dataByPerson;
  //console.log(attendancePairs);
  /*const totalTime: any =
    !attendancePairs.loading && selectedPerson && dataByPerson[selectedPerson]
      ? dataByPerson[selectedPerson]?.totalTimeJSX
      : 0;*/

  //base hours to calculate holiday hours (for total time)
  const holidayCountPerson: any =
    holidayCount &&
    holidayCount.find((i: any) => i.cardname === selectedPerson);

  const baseHours =
    holidayCountPerson && holidayCountPerson.base_hours_per_day
      ? Number(holidayCountPerson.base_hours_per_day)
      : 8;

  const sumByPerson = attendanceSums?.rows?.find(
    (s: any) => s?.cardperson === selectedPerson
  );
  const totalTimeWork: number =
    !attendanceSums?.loading &&
    selectedPerson &&
    sumByPerson &&
    Number(sumByPerson?.minutes);
  // !attendancePairs.loading && selectedPerson && dataByPerson[selectedPerson]
  //   ? dataByPerson[selectedPerson]?.totalMinutes
  //   : 0;

  const totalTimeWorkString = minutesToString(totalTimeWork, false);

  const totalTimeWorkCustom: number =
    !attendancePairs.loading && selectedPerson && dataByPerson[selectedPerson]
      ? dataByPerson[selectedPerson]?.totalMinutesCustom
      : 0;
  const totalTimeWorkCustomString = minutesToString(totalTimeWorkCustom, false);

  const totalTimeRemoteWork: number =
    !attendancePairs.loading && selectedPerson && dataByPerson[selectedPerson]
      ? dataByPerson[selectedPerson]?.totalMinutesRemote
      : 0;
  const totalTimeRemoteWorkString = minutesToString(totalTimeRemoteWork, false);

  /// [1,2,3,5,6]
  const totalTimeHoliday: number = holidays
    ? holidays.filter(
        (h: any) =>
          h.employeeID === selectedEmployeeData.id &&
          new Date(h.date).getMonth() === date.month &&
          new Date(h.date).getFullYear() === date.year &&
          // [1, 2, 3, 5, 6].includes(h.type)
          [1, 2].includes(h.type)
      ).length *
      baseHours *
      60
    : 0;

  const totalTimeOtherHoliday: number = holidays
    ? holidays.filter(
        (h: any) =>
          h.employeeID === selectedEmployeeData.id &&
          new Date(h.date).getMonth() === date.month &&
          new Date(h.date).getFullYear() === date.year &&
          [5, 10].includes(h.type)
      ).length *
      baseHours *
      60
    : 0;

  const totalDaysSick: number = holidays
    ? holidays.filter(
        (h: any) =>
          h.employeeID === selectedEmployeeData.id &&
          new Date(h.date).getMonth() === date.month &&
          new Date(h.date).getFullYear() === date.year &&
          h.type === 7
      ).length
    : 0;

  const totalTimeHolidayString = minutesToString(totalTimeHoliday, false);
  const totalTime: number =
    totalTimeWork + totalTimeHoliday + totalTimeOtherHoliday;
  const totalTimeJSX = minutesToJSX(totalTime, false);
  const totalTimeString = minutesToString(totalTime, false);

  const totals = {
    holidays: totalTimeHolidayString,
    work: totalTimeWorkString,
    customWork: totalTimeWorkCustomString,
    remoteWork: totalTimeRemoteWorkString,
    all: totalTimeString,
    sickDays: totalDaysSick,
  };

  const cards = !cardsAndEmployees.loading
    ? cardsAndEmployees?.multiple?.cards?.rows
    : [];
  const employees = !cardsAndEmployees.loading
    ? cardsAndEmployees?.multiple?.employees?.rows
    : [];
  const employeeGroups = !cardsAndEmployees.loading
    ? cardsAndEmployees?.multiple?.employeeGroups?.rows
    : [];

  const cardsFull =
    !cardsAndEmployees.loading &&
    employees?.length &&
    cards?.length &&
    employeeGroups?.length
      ? employees
          .map((employee: any) => ({
            ...employee,
            ...cards.find((card: any) => card.cardname === employee.name),
          }))
          .filter(
            (employee: any) =>
              cards.filter((card: any) => card.cardname !== employee.name)
                .length
          )
      : [];

  const currentEmployee = selectedPerson
    ? cardsFull.find((c: any) => c.cardname === selectedPerson)
    : null;

  async function doServerWork() {
    setUpdatedFromDevice(false);
    setServerStatus(false);
    const response2 = await fetch(
      //"http://46.232.128.36/hotelhub/helper/run-attendance.php"
      `${URL}/backend/run-attendance-remote-office.php`,
      { credentials: "include" }
    );
    const result2 = await response2.json();
    const response = await fetch(
      //"http://46.232.128.36/hotelhub/helper/run-attendance.php"
      `${URL}/backend/run-attendance-remote-hotel.php`,
      { credentials: "include" }
    );
    const result = await response.json();
    const combined = await { ...result, ...result2 };
    combined.success = result.success && result2.success;
    setServerStatus(combined);
    setUpdatedFromDevice(combined.success);
    setDeviceUpdates((prev: number) => prev + 1);
    setUpdate((prev: number) => prev + 1);
  }

  useEffect(() => {
    /// UPDATES DB FROM DEVICE
    doServerWork();
    const updateDBfromDevice = setInterval(() => doServerWork(), 20000);
    return () => clearInterval(updateDBfromDevice);
  }, []);

  function changeEntryActiveStatus(item: any) {
    const idFromField =
      item.parentNode.parentNode.querySelector("[name=id").value;
    const activeFromField =
      item.parentNode.parentNode.querySelector("[name=id").value;
    setActive(idFromField, activeFromField, "attendance");
    setUpdate((prev: number) => prev + 1);
  }
  const cal = generateCalendarData(
    { month: date.month, year: date.year },
    { loading: true }
  );
  const calWeek = generateCalendarData(date, { loading: true });

  return (
    <>
      <div
        className={
          "deviceUpdate" +
          (updatedFromDevice ? " updated" : " updating") +
          (serverStatus && !serverStatus.success ? " failure" : "") +
          (deviceUpdateBoxHide ? " hide" : "")
        }
        onClick={() => setDeviceUpdateBoxHide((prev: boolean) => !prev)}
      >
        {updatedFromDevice ? (
          <p>✓</p>
        ) : !serverStatus ? (
          <p>Pobieranie danych...</p>
        ) : (
          <></>
        )}
        {serverStatus && serverStatus?.hotel?.deviceLink === false ? (
          <p>
            Brak łączności z urządzeniem HOTEL
            <br />
            od <strong>{serverStatus.hotel.last}</strong>
          </p>
        ) : (
          <></>
        )}
        {serverStatus && serverStatus?.office?.deviceLink === false ? (
          <p>
            Brak łączności z urządzeniem BIURO <br />
            od <strong>{serverStatus?.office?.last}</strong>
          </p>
        ) : (
          <></>
        )}
        {serverStatus && serverStatus?.office?.serverLink === false ? (
          <p>
            Brak łączności z serwerem BIURO <br />
            ostatnie dane:{" "}
            <strong>
              {new Date(serverStatus?.office?.last_timestamp).toLocaleString()}
            </strong>
          </p>
        ) : (
          <></>
        )}
        {serverStatus && serverStatus?.hotel?.serverLink === false ? (
          <p>
            Brak łączności z serwerem HOTEL <br />
            ostatnie dane:{" "}
            <strong>
              {new Date(serverStatus?.hotel?.last_timestamp).toLocaleString()}
            </strong>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className={"attendanceWrapper" + (selectedPerson ? "" : " nobody")}>
        <div
          className={
            "attendanceTableWrapper" + (selectedPerson ? "" : " nothing")
          }
          style={{ position: "relative" }}
        >
          <div className="attendanceTableHeader">
            <div className="selectedPerson">
              {currentEmployee ? (
                <div onClick={() => setSmallList((prev: boolean) => true)}>
                  {currentEmployee.first_name + " " + currentEmployee.last_name}
                </div>
              ) : (
                <div>{">"}</div>
              )}
              {currentEmployee &&
              selectedPerson &&
              employeeViewMode !== "holidays" &&
              employeeViewMode !== "holidayRequests" ? (
                <>
                  <div
                    className="sumHours"
                    title={
                      "PRACA: " +
                      totalTimeWorkString +
                      "\r\nURLOP: " +
                      totalTimeHolidayString
                    }
                  >
                    <div className="sumHoursLabel">SUMA</div>
                    <div className="sumHoursString">{totalTimeJSX}</div>
                  </div>
                </>
              ) : (
                <div></div>
              )}
              <div
                className={"smallEmployeeList" + (smallList ? " visible" : "")}
                style={{ zIndex: "40" }}
                //onMouseOut={() => setSmallList((prev:boolean) => false)}
                onClick={() => setSmallList((prev: boolean) => false)}
                onMouseOut={() => setSmallList((prev: boolean) => false)}
                onMouseOver={() => setSmallList((prev: boolean) => true)}
              >
                <Employees
                  employeeViewMode="empty"
                  deviceUpdates={deviceUpdates}
                  cardsFull={cardsFull}
                  lastCheckInRows={lastCheckInRows}
                  cardsAndEmployees={cardsAndEmployees}
                  employeeGroups={employeeGroups}
                  selectedPerson={selectedPerson}
                  setSelectedPerson={setSelectedPerson}
                  setSelectedEmployeeData={setSelectedEmployeeData}
                  dataByPerson={dataByPerson}
                  location={location}
                  holidayCount={holidayCount}
                  // totalTime={totalTime}
                />
              </div>
            </div>
            {["holidays", "holidayRequests"].includes(employeeViewMode) ? (
              <SelectHolidayType
                all={true}
                setSumType={setHolidaySumType}
                holidayTypes={holidayTypes}
              />
            ) : (
              <></>
            )}
            <CalendarControls
              date={date}
              cal={cal}
              calWeek={calWeek}
              items={attendanceRows}
              extended={false}
              yearSet={
                employeeViewMode === "holidays" ||
                employeeViewMode === "holidayRequests"
                  ? true
                  : false
              }
              dateSetter={setDate}
              mode="month"
            />
            <div></div>
          </div>

          {employeeViewMode === "holidays" ||
          employeeViewMode === "holidayRequests" ? (
            <HolidaysTable
              holidayCount={holidayCount}
              selectedPerson={selectedPerson}
              year={date.year}
              update={smallUpdate + update}
              holidaySumType={holidaySumType}
            />
          ) : attendancePairs.loading ? (
            <Loading />
          ) : selectedPerson ? (
            <>
              <NewAttendanceTable
                selectedPerson={selectedPerson}
                selectedEmployeeData={selectedEmployeeData}
                holidays={holidays}
                holidaysSuggested={holidaysSuggested}
                calendar={cal}
                date={date}
                update={update}
                setUpdate={setUpdate}
                setSmallUpdate={setSmallUpdate}
                setActive={setActive}
                location={location}
                employees={employees}
              />
              {/* <AttendanceTable
                selectedPerson={selectedPerson}
                selectedEmployeeData={selectedEmployeeData}
                holidays={holidays}
                holidaysSuggested={holidaysSuggested}
                rows={attendanceRows}
                loading={attendancePairs.loading && holidaysRaw.loading}
                calendar={cal}
                date={date}
                update={update}
                setUpdate={setUpdate}
                setSmallUpdate={setSmallUpdate}
                setActive={setActive}
                location={location}
              /> */}

              <AttendanceSummary totals={totals} />
            </>
          ) : (
            <Info type="pickPerson" />
          )}
        </div>
        <div className="attendanceBigPanel">
          <div className="attendanceChooser">
            <div>
              {selectedPerson ? (
                <button
                  className="button"
                  style={{ padding: "0px 7px", fontSize: "25px" }}
                  onClick={() => setSelectedPerson(false)}
                >
                  {"◂"}
                </button>
              ) : (
                <></>
              )}
              <button
                className={
                  "button huge hotel" + (location === "hotel" ? " active" : "")
                }
                onClick={() => {
                  navigate("/attendance/hotel");
                  setLocation("hotel");
                }}
              >
                HOTEL
              </button>
              <button
                className={
                  "button huge biuro" + (location === "biuro" ? " active" : "")
                }
                onClick={() => {
                  navigate("/attendance/biuro");
                  setLocation("biuro");
                }}
              >
                BIURO
              </button>
            </div>
            <div>
              <button
                className={
                  "button" + (employeeViewMode === "present" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("present")}
              >
                W pracy
              </button>
              <button
                className={
                  "button" + (employeeViewMode === "sums" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("sums")}
              >
                Suma miesiąca
              </button>
              <button
                className={
                  "button" + (employeeViewMode === "holidays" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("holidays")}
              >
                Dni wolne
              </button>
              <button
                className={
                  "button" +
                  (employeeViewMode === "holidayRequests" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("holidayRequests")}
              >
                Wnioski
              </button>
              <button
                className={
                  "button" +
                  (employeeViewMode === "holidayReports" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("holidayReports")}
              >
                Raporty urlopy
              </button>
              <button
                className={
                  "button" +
                  (employeeViewMode === "attendanceReports" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("attendanceReports")}
              >
                Raporty pełne
              </button>
              <button
                className={
                  "button" +
                  (employeeViewMode === "annualReports" ? " active" : "")
                }
                onClick={() => {
                  setEmployeeViewMode("annualReports");
                  setSelectedPerson(false);
                }}
              >
                Sumy
              </button>
              <button
                className={
                  "button" + (employeeViewMode === "list" ? " active" : "")
                }
                onClick={() => setEmployeeViewMode("list")}
              >
                Zdarzenia
              </button>
            </div>
            {selectedPerson ? (
              <></>
            ) : employeeViewMode === "present" ||
              employeeViewMode === "holidays" ||
              employeeViewMode === "holidayRequests" ? (
              <></>
            ) : (
              <CalendarControls
                date={date}
                cal={cal}
                calWeek={calWeek}
                items={attendanceRows}
                extended={false}
                dateSetter={setDate}
                mode="month"
                yearSet={
                  employeeViewMode === "holidayReports" ||
                  employeeViewMode === "annualReports"
                    ? true
                    : false
                }
                noFuture={true}
                cl="tiny"
              />
            )}
          </div>
          {employeeViewMode === "holidayRequests" ? (
            <HolidayRequests
              update={update}
              setUpdate={setUpdate}
              employees={employees}
              selectedPerson={selectedPerson}
              setSelectedEmployeeData={setSelectedEmployeeData}
              setSmallUpdate={setSmallUpdate}
              setSelectedPerson={setSelectedPerson}
              seeRequest={seeRequest}
            />
          ) : employeeViewMode === "holidayReports" ? (
            <HolidayReports
              location={location}
              date={date}
              setDate={setDate}
              sumType={holidaySumType}
              setSumType={setHolidaySumType}
              holidayTypes={holidayTypes}
            />
          ) : employeeViewMode === "attendanceReports" ? (
            <AttendanceReports
              date={date}
              location={location}
              groups={employeeGroups}
              lastCheckIns={
                !lastCheckIns.loading ? lastCheckIns.rows : undefined
              }
            />
          ) : employeeViewMode === "annualReports" ? (
            <>
              <AnnualReports
                date={date}
                location={location}
                groups={employeeGroups}
                holidayTypes={holidayTypes}
              />
              {/* <AttendanceSumsYear year={date.year} employees={employees} /> */}
            
            </>
          ) : employeeViewMode === "list" ? (
            <ErrorItems
              name={
                location === "hotel"
                  ? "attendance"
                  : location === "biuro"
                  ? "attendance_3maja"
                  : ""
              }
              process={process}
              limit={20}
              query={{
                cardPerson: selectedPerson ? selectedPerson : undefined,
                location: location,
              }}
              controls={false}
              updatePush={update}
              active={false}
              customView={attendanceView}
              heading="Edycja wpisu"
              editable={false}
            />
          ) : (
            <Employees
              employeeViewMode={employeeViewMode}
              deviceUpdates={deviceUpdates}
              cardsFull={cardsFull}
              lastCheckInRows={lastCheckInRows}
              cardsAndEmployees={cardsAndEmployees}
              employeeGroups={employeeGroups}
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
              setSelectedEmployeeData={setSelectedEmployeeData}
              dataByPerson={dataByPerson}
              location={location}
              holidayCount={holidayCount}
            />
          )}
        </div>
        <div className={"attendance-list" + " hidden"}>
          <ErrorItems
            name={
              location === "hotel"
                ? "attendance"
                : location === "biuro"
                ? "attendance_3maja"
                : ""
            }
            process={process}
            limit={20}
            query={selectedPerson ? { cardperson: selectedPerson } : undefined}
            controls={false}
            updatePush={update}
            active={false}
            customView={attendanceView}
            heading="Edycja wpisu"
            editable={false}
          />
        </div>
      </div>
    </>
  );
}
