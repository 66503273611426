export default function minutesToJSX(
  minNum: number,
  warning: boolean,
  t1?: string
) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  const stillWorking =
    !minNum && t1
      ? new Date().getTime() - new Date(t1).getTime() < 1000 * 60 * 60 * 9
      : false;
  return minNum ? (
    <>{(hours ? hours + " h" : "") + (minutes ? " " + minutes + " min" : "")}</>
  ) : warning && stillWorking ? (
    <span style={{ fontSize: "12px", fontWeight: "bold", color: "green" }}>
      w pracy
    </span>
  ) : warning ? (
    <span style={{ fontSize: "16px", fontWeight: "bold", color: "brown" }}>
      ???
    </span>
  ) : (
    <></>
  );
}

export function minutesToString(minNum: number, warning: boolean) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  return minNum
    ? (hours ? hours + " h" : "") + (minutes ? " " + minutes + " min" : "")
    : warning
    ? "?"
    : "";
}

export function minutesToShortJSX(minNum: number, warning: boolean) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  return minNum ? (
    <>
      <span>{hours ? hours : "0"}</span>
      {hours ? <></> : <></>}
      <span
        style={{
          lineHeight: "70%",
          paddingBottom: "2px",
          fontSize: "9px",
          color: "grey",
        }}
      >
        {minutes ? (minutes < 10 ? "0" + minutes : minutes) : "00"}
      </span>
    </>
  ) : warning ? (
    "?"
  ) : (
    ""
  );
}
