import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ViewContext } from "../../contexts/ViewContext";
import insertGeneric from "../../dataFetch/insertGeneric";
import GenericForm from "../../forms/GenericForm";

export default function AddMissingNew({
  event,
  date,
  defaultEvent,
  setUpdate,
  location,
}: {
  event: any;
  date: any;
  defaultEvent: number;
  setUpdate: any;
  location: string;
}) {
  const viewContext = useContext(ViewContext);
  const { permissions } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);
  //console.log(event);
  const datumCor = new Date(date);
  datumCor.setHours(10);
  return !(p > 3) ? (
    <></>
  ) : (
    <div
      className="missing"
      onClick={() =>
        viewContext.setModal({
          show: true,
          heading: "Dodaj zdarzenie",
          content: (
            <GenericForm
              formContent={[
                {
                  name: "cardperson",
                  initialValue: event.cardname,
                  label: "Osoba",
                  disabled: true,
                },
                {
                  name: "typeshort",
                  initialValue: defaultEvent,
                  label: "Rodzaj rekordu",
                  element: "select",
                  optionsList: [
                    {
                      id: 9,
                      name: "",
                      active: true,
                    },
                    {
                      id: 0,
                      name: "przyjście do pracy",
                      active: true,
                    },
                    {
                      id: 1,
                      name: "wyjście z pracy",
                      active: true,
                    },
                    {
                      id: 2,
                      name: "ZDALNIE - początek pracy",
                      active: true,
                    },
                    {
                      id: 3,
                      name: "ZDALNIE - koniec pracy",
                      active: true,
                    },
                    {
                      id: 4,
                      name: "INNE - początek pracy",
                      active: true,
                    },
                    {
                      id: 5,
                      name: "INNE - koniec pracy",
                      active: true,
                    },
                  ],
                },
                {
                  name: "date",
                  initialValue: datumCor.toISOString().slice(0, 10),
                  label: "Data",
                  inputType: "date",
                },
                {
                  name: "time",
                  initialValue: "",
                  label: "Godzina",
                  inputType: "time",
                  autoFocus: true,
                },
              ]}
              onSubmit={async (values: any) => {
                const timeObj = new Date(values.date);

                const dayObj = new Date(timeObj);
                dayObj.setHours(12);

                timeObj.setHours(
                  values.time.slice(0, 2),
                  values.time.slice(3, 5)
                );

                const timestring =
                  dayObj.toISOString().slice(0, 10) +
                  " " +
                  timeObj.toTimeString().slice(0, 8);

                const offset = timeObj.getTimezoneOffset() * 60000;
                const timestamp = (timeObj.getTime() - offset) / 1000;
                await insertGeneric("set-attendance", {
                  cardname: "",
                  cardperson: event.cardname,
                  carduserid: event.employeeID,
                  timestamp: timestamp,
                  timestring: timestring,
                  typeshort: values.typeshort,
                  location: location,
                });
                setUpdate((prev: number) => prev + 1);
                viewContext.setModal((prev: any) => ({ ...prev, show: false }));
              }}
            />
          ),
        })
      }
    >
      +
    </div>
  );
}
