import { useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { isDateBetween } from "../functions/prettyDate";

export default function useCalendarTVschedule(date: any) {
  const [fullScreen, setFullScreen] = useState(false);
  const [path, setPath] = useState(undefined);
  const [defaultVideo, setDefaultVideo] = useState(undefined);

  const { loadCount, rows, loading } = useGeneric({
    name: "get-tv-schedule",
    reload: true,
    secondsToReload: 30,
    query: {
      active: 1,
    },
  });

  useEffect(() => {
    const d = date || new Date();

    const currentData =
      !loading &&
      rows &&
      rows.length &&
      rows?.find((item: any) =>
        isDateBetween(d, item?.time_from, item?.time_to)
      );

    setDefaultVideo(rows?.find((item: any) => item.default === 1)?.path);

    // console.log(loadCount, "update",rows, currentData);
    if (!loading && currentData) {
      setFullScreen(Boolean(currentData?.fullscreen));
      if (currentData?.path) {
        setPath(currentData?.path);
      }
    } else {
      setFullScreen(false);
      setPath(undefined);
    }
  }, [loadCount]);

  return {
    fullScreen: fullScreen,
    path: path || defaultVideo || "vertical3.mp4",
  };
}
