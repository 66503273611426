import { useContext, useEffect } from "react";
import { FormDataContext } from "../Form";
import { useFormContext } from "react-hook-form";
import {
  formField,
  option,
  optionSingle,
  optionsList,
} from "../formContentType";
import Loading from "../../common/Loading";

export default function SelectPicker({
  schema,
  hidden,
  setManualChanges,
}: {
  schema: formField;
  hidden: any;
  setManualChanges: Function;
}) {
  const { options, data } = useContext(FormDataContext);
  const {
    name,
    getOptionsFrom,
    required,
    validate,
    optionsList,
    initialValue,
    pickerMulti,
    onNull,
  } = schema;
  const {
    register,
    setValue,
    getValues,
    formState: { errors, touchedFields },
  } = useFormContext();


  const optionsSpecs = getOptionsFrom?.name ? getOptionsFrom?.name : getOptionsFrom;
  const optionsForThis: any =
    options && optionsSpecs
      ? options[optionsSpecs as keyof typeof options]
      : undefined;

  let optionsObject = optionsForThis ? optionsForThis.rows : optionsList;

  if (schema?.validate) {
    optionsObject = optionsObject?.filter(
      (o: any) => schema?.validate(o?.id, getValues()) === true
    );
    // console.log(optionsObject);
    // console.log(
    // schema?.validate && schema?.validate(getValues(name), getValues())
    // );
  }

  useEffect(() => {
    if (data && data[name as keyof typeof data] !== undefined) {
      setValue(name, data[name as keyof typeof data]);
    } else if (initialValue) {
      setValue(name, initialValue);
    }
  }, [JSON.stringify(optionsObject)]);

  // console.log(name, getValues(name));

  // console.log(
  //   schema?.subDefaults?.["_" + name?.split("%")?.[0]]
  //     ? String(schema?.subDefaults?.["_" + name?.split("%")?.[0]])
  //     : onNull
  // );
  return optionsForThis?.rows && !Object.keys(optionsObject).length ? (
    <div className="loadingOptions">
      <Loading little={true} />
    </div>
  ) : (
    <div
      className="select-picker"
      style={schema.style}
      onClick={() => console.log(getValues())}
    >
      <input
        className={
          (errors?.[name] ? "error" : "") +
          (touchedFields?.[name] ? " touched" : "")
        }
        //disabled={schema?.readOnly ? true : false}
        type="hidden"
        title={errors[name]?.message?.toString()}
        id={name}
        {...register(name, {
          required: required,
          validate: validate,
          onChange: () => setManualChanges((prev: number) => prev + 1),
          value: schema?.subDefaults?.["_" + name?.split("%")?.[0]]
            ? String(schema?.subDefaults?.["_" + name?.split("%")?.[0]])
            : onNull,
          // value: "",
        })}
      />
      {optionsObject?.map((option: optionSingle) => (
        <div
          style={{ display: hidden ? "none" : undefined }}
          className={
            "select-picker-option" +
            (String(getValues(name)) == String(option.id) ||
            (pickerMulti &&
              typeof getValues(name) === "string" &&
              getValues(name)
                ?.split(",")
                ?.find((f: any) => Number(f) == option.id))
              ? " active"
              : "")
          }
          onClick={() => {
            if (pickerMulti) {
              const stringValue = getValues(name);
              let values = stringValue ? stringValue?.split(",") : [];
              if (values.find((v: string) => Number(v) === Number(option.id))) {
                values = values.filter(
                  (v: string) => Number(v) !== Number(option.id)
                );
              } else {
                values.push(option.id);
              }
              values = values
                .map((v: any) => Number(v))
                ?.sort((a: number, b: number) => b - a);
              setValue(name, values.join(","));
            } else {
              if (getValues(name) === String(option.id)) {
                setValue(name, 0);
              } else {
                setValue(name, String(option.id));
              }
            }
          }}
          key={name + "." + option.id}
        >
          {option?.colorFrame ? (
            <div
              className="color-frame"
              style={{ background: option.colorFrame }}
            ></div>
          ) : (
            <></>
          )}
          {option?.prefix ? (
            <div className="option-prefix">{option.prefix}</div>
          ) : (
            <></>
          )}
          {option.name}
        </div>
      ))}
    </div>
  );
}
