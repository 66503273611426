import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";

export default function VoucherLSIChanges({
  update,
  voucherID,
}: {
  update: number;
  voucherID?: number;
}) {
  const { loading, rows } = useGeneric({
    name: "lsi_voucher_changes",
    update: update,
    query: {
      id: voucherID,
    //   pure_lsi: true,
    },
  });
  return (
    <table className="data-table voucher-changes-table">
      {!loading &&
        rows?.map((i: any) => (
          <tr>
            <td>{prettyDate(i?.Data)}</td>
            <td>{i?.nazwa_skrocona?.replace(/Voucher /, "")}</td>
            <td style={{ color: i?.Stan?.at(0) === "-" ? "brown" : "green" }}>
              {i?.Stan?.at(0) != "-" ? "+" : ""}
              {Math.round(i?.Stan)}
            </td>
          </tr>
        ))}
    </table>
  );
}
