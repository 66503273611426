import { useEffect, useState } from "react";
import useGeneric from "../../dataFetch/useGeneric";
import { forms } from "../formContentType";

export default function useOptions(schema: forms | any) {
  const formContent = schema?.[0]?.column
    ? schema
    : schema && Object.keys(schema).map((key: string) => schema[key].content);

  const [options, setOptions] = useState<any>({
    data: {},
    loading: true,
  });

  const prefetchDBs =
    formContent &&
    formContent
      ?.flat()
      ?.map(
        (f: any) => {
          if (Array.isArray(f)) {
            return f.map((ff: any) =>
              ff?.getOptionsFrom?.name
                ? ff?.getOptionsFrom?.name
                : ff?.getOptionsFrom
            );
          } else {
            return f?.getOptionsFrom?.name
              ? f?.getOptionsFrom?.name
              : f?.getOptionsFrom;
          }
        }
        // Array.isArray(f)
        //   ? f.map((ff: any) => ff?.getOptionsFrom)
        //   : f?.getOptionsFrom
      )
      ?.flat()
      ?.filter((item: any) => item);

  const prefetchParams =
    formContent &&
    formContent
      ?.flat()
      ?.map((f: any) => {
        if (Array.isArray(f)) {
          return f.map((ff: any) =>
            ff?.getOptionsFrom?.params
              ? {
                  n: ff?.getOptionsFrom?.name,
                  prop: ff?.name,
                  ...ff?.getOptionsFrom?.params,
                }
              : undefined
          );
        } else {
          return f?.getOptionsFrom?.params
            ? {
                n: f?.getOptionsFrom?.name,
                prop: f?.name,
                ...f?.getOptionsFrom?.params,
              }
            : {};
        }
      })
      ?.flat()
      ?.filter((item: any) => item);

  const fetch = useGeneric({
    name: prefetchDBs,
    multiParams: prefetchParams,
    limit: 100,
    active: true,
  });

  const fetchData =
    !fetch.loading && fetch.multiple ? fetch.multiple : undefined;

  useEffect(() => {
    if (fetchData) {
      //  console.log(fetchData);
      setOptions({ data: fetchData, loading: false });
    }
  }, [fetch.loading]);
  // console.log(options);
  return options;
}
