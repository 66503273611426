import { CalendarContext } from "../contexts/CalendarContext";
import { isSameDay } from "../schedules/ScheduleTable";
import CalendarTVevent from "./CalendarTVevent";
import { compareTimeStrings } from "../functions/tools";
import Clock from "./Clock";
import logo from "../../images/logo.svg";
import useGeneric from "../dataFetch/useGeneric";
import generateCalendarData from "./generateCalendarData";
import { setVersion, useVersion } from "../useVersion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useCalendarTVschedule from "./useCalendarTVschedule";

export default function CalendarTVexposed() {
  // const today = new Date("2024-05-11 13:00:00");

  const location = useLocation();
  const exp = location.pathname.match(/\/exp$/);

  const customDate = location.pathname.match(/\/(-?[0-9][0-9]?)/)?.[1];
  const dateOffset = customDate && Number(customDate);
  const today = new Date();

  if (dateOffset) {
    today.setDate(today.getDate() + dateOffset);
  }

  const [currentWeatherData, setCurrentWeatherData]: any = useState();
  //   {
  //   values: {
  //     weatherCode: "4213",
  //     temperature: 5,
  //   },
  // }
  const [weatherUpdates, setWeatherUpdates] = useState(0);

  // const [onlyVideo, setOnlyVideo] = useState(false);

  // WEATHER
  async function weather() {
    const weatherApi =
      "https://api.tomorrow.io/v4/weather/realtime?" +
      "location=51.24753370000001,22.5002758" +
      "&" +
      "units=metric" +
      "&" +
      "apikey=CPzz4RgpGmpn3xaInFcYOzNZhA7W3tZ3";
    const fetchWeather = await fetch(weatherApi);
    const weatherData = await fetchWeather.json();
    console.log(weatherData);
    setCurrentWeatherData(weatherData?.data);
  }

  useEffect(() => {
    const first = setTimeout(weather, 3 * 60 * 1000); // 3 minute first

    const f = setInterval(weather, 30 * 60 * 1000); // update every 30 minutes
    //rate limits: 25 / hour, 500/day
    return () => {
      clearInterval(f);
      clearTimeout(first);
    };
  }, []);

  //

  // DB SCHEDULING FOR SCREEN CONTENT
  const { fullScreen, path } = useCalendarTVschedule(today);
  // console.log(fullScreen, path)

  useEffect(() => {
    if (weatherUpdates > 0) {
      weather();
    }
  }, [weatherUpdates]);

  const versioning = useVersion(1);
  const [finishedUpdate, setFinishedUpdate] = useState(false);
  // document.body.requestFullscreen();
  async function doUpdate() {
    if (versioning && versioning?.doUpdate) {
      const result = await setVersion(1, versioning.current_version);
      if (result?.response?.response === "OK") {
        setFinishedUpdate(true);
        setTimeout(() => window.location.reload(), 5000); // 5s
      }
    }
  }

  doUpdate();

  const data = useGeneric({
    name: "event-calendar-basic",
    limit: 100,
    query: {
      date: {
        value: today.toISOString().slice(0, 10),
        db: "d",
      },
      // active: 1
    },
    reload: true,
    secondsToReload: 20,
  });

  // console.log(data);

  const error = data?.error;

  const cal = generateCalendarData(
    { month: today.getMonth(), year: today.getFullYear() },
    data
  );

  const todayEvents = cal?.dates?.find((date: any) =>
    isSameDay(date.obj, today)
  )?.events;

  const sortedEvents = todayEvents.sort((a: any, b: any) => {
    const timeFromA = JSON.parse(a?.dates)?.find((i: any) =>
      isSameDay(i.date, today)
    )?.time_from;
    const timeFromB = JSON.parse(b?.dates)?.find((i: any) =>
      isSameDay(i.date, today)
    )?.time_from;
    return compareTimeStrings(timeFromA, timeFromB);
  });

  const vid = useCallback(
    (back?: Boolean, path?: string, style?: any) => {
      return (
        <div
          className={"calendar-tv-video" + (back ? " back" : "")}
          style={style}
        >
          <video
            style={{ filter: "saturate(.92)" }}
            src={"./img/" + path}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          />
        </div>
      );
    },
    [fullScreen, path]
  );
  // document.body.requestFullscreen();
  // const c = setTimeout(() => {document.body.requestFullscreen()}, 500);

  return fullScreen ? (
    <div
      className="calendarTV"
      style={{
        minHeight: "100vh",
        margin: 0,
        borderRadius: 0,
        animationName: "fadeIn",
        top: "50%",
        width: "101vw",
        left: "50%",
        translate: "-50% -50%",
        position: "fixed",
        background: "black",
      }}
    >
      {vid(false, path, { translate: "-50% -50%" })}
    </div>
  ) : !cal ? (
    <>Brak danych</>
  ) : (
    <div className="calendarTV">
      {finishedUpdate ? (
        <div className="update-notice">Aktualizacja systemu...</div>
      ) : (
        <></>
      )}

      <div className="today-events-heading">
        <img src={logo} />
        <div className="today-date-time">
          <div
            className="today-date"
            onClick={() => setWeatherUpdates((prev: number) => prev + 1)}
          >
            {today.toLocaleDateString("pl-PL", {
              weekday: "short",
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
          <Clock />
        </div>
      </div>
      {error ? (
        <>
          <div
            style={{
              textAlign: "center",
              padding: "100px",
              fontSize: "34px",
              borderRadius: "15px",
              margin: "50px 25px",
              color: "brown",
              border: "1px solid brown",
            }}
          >
            Brak połączenia
          </div>
          <div
            style={{ padding: "70px", textAlign: "center", fontSize: "28px" }}
          >
            Komunikat serwera:
            <br /> {error}
          </div>
        </>
      ) : (
        <>
          <div
            className={
              "today-events" +
              (sortedEvents?.length > 10
                ? " super-long-list"
                : sortedEvents?.length > 7
                ? " long-list"
                : "")
            }
          >
            {currentWeatherData ? (
              <div className="weather">
                <img
                  src={`./img/weather/${currentWeatherData?.values?.weatherCode}0.png`}
                  alt=""
                />
                <div className="temp">
                  <span>
                    {Math.round(currentWeatherData?.values?.temperature)}
                  </span>
                  <span>°C</span>
                </div>
                <div className="acknowledgement">Źródło: tomorrow.io</div>
              </div>
            ) : (
              <></>
            )}
            {sortedEvents && !sortedEvents?.length ? (
              vid(false, path)
            ) : (
              <>
                {vid(true, path)}{" "}
                {sortedEvents?.map((event: any) => (
                  <>
                    <CalendarTVevent event={event} date={today} />
                  </>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
