import { useContext } from "react";
import { ViewContext } from "../contexts/ViewContext";

import editIcon from "../../images/icons/pencil.png";
import viewIcon from "../../images/icons/view.svg";
import crossIcon from "../../images/icons/cross.png";
import venueIcon from "../../images/icons/room3.svg";
import mailIcon from "../../images/icons/envelope.png";
import personIcon from "../../images/icons/person.svg";
import businessIcon from "../../images/icons/briefcase.svg";
import setActive from "../dataFetch/setActive";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import idToName, {
  dish_type,
  getPropByDate,
  idToJSX,
  music_type,
} from "./definitions/common";

import musicIcon from "../../images/icons/new/music.svg";
import eatIcon from "../../images/icons/new/fork.svg";

import infoIcon from "../../images/icons/info.svg";
import tvIcon from "../../images/icons/tv.svg";
import EventViewFull from "./EventViewFull";

import prettyDate, { compareDay, dayDiff } from "../functions/prettyDate";
import { isSameDay } from "../schedules/ScheduleTable";
import useEventViewItems from "./useEventViewItems";
import { addAlpha, colorShade } from "./definitions/functions";
import AgendaView from "./AgendaView";
import EventService from "./EventService";
import DepositIcon from "./DepositIcon";
import AgendaGenerateButtons from "./AgendaGenerateButtons";
import { objectOrJSONparse } from "../functions/tools";
import AddPotentialEvent from "./AddPotentialEvent";

export default function EventInList({
  event,
  editor,
  list,
  date,
  potential,
}: {
  event: any;
  editor: any;
  list?: Boolean;
  date: Date;
  potential?: Boolean;
}) {
  const modal = useContext(ViewContext);
  const calendar = useContext(CalendarContext);
  const user = useContext(UserContext);
  const { auth } = calendar;

  const view = useEventViewItems(event, date, potential);
  // console.log(potential, view);

  const isCreator = user.userID === event.created_by;
  const isCreatorActive = calendar.creatorHighlight;
  const isAdminOrCreator = user.role === 0 || isCreator;

  let whichDayPotential = "";
  if (potential) {
    const dates = objectOrJSONparse(event?.dates);

    whichDayPotential =
      dates && dates.length > 1
        ? dates
            .map((d: any) => d.date)
            .indexOf(date?.toISOString().slice(0, 10)) + 1
        : undefined;
  }

  return view || potential ? (
    <div
      className={
        "eventInList" +
        (isCreatorActive ? (isCreator ? " creator" : " non-creator") : "") +
        (list ? " list-event" : "") +
        (view?.values.dayNumeral ? " multi-day" : "")
      }
      //  key={event.id}
      style={{
        background:
          "linear-gradient(40deg," +
          addAlpha(colorShade(event?.color, 50), 0.35) +
          ", transparent, transparent)",
      }}
    >
      <div className="eventContentWrap">
        <div>
          <div className="options">
            <div>
              {/* <img
              src={eyeIcon}
              onClick={() =>
                modal.setModal({
                  show: true,
                  heading: "Szczegóły wydarzenia",
                  content: <EventFullView event={event} />,
                })
              }
            />*/}
              <a
                style={event.email ? {} : { opacity: ".24" }}
                href={event.email ? "mailto:" + event.email : undefined}
                title="Napisz wiadomość e-mail"
              >
                <img src={mailIcon} />
              </a>
            </div>
            {auth?.edit ? (
              <>
                <div
                  onClick={() => {
                    return !potential
                      ? editor(
                          event,
                          { obj: date }, //{ obj: undefined },
                          { modal: modal, calendar: calendar },
                          auth
                        )
                      : auth?.edit
                      ? potential
                        ? modal.setModal({
                            show: true,
                            heading: "Edycja niepotwierdzonego wydarzenia",
                            style: { width: "750px" },
                            content: (
                              <AddPotentialEvent
                                auth={auth}
                                event={event}
                                date={date}
                                calendar={calendar}
                                setModal={modal.setModal}
                              />
                            ),
                          })
                        : editor(
                            event,
                            { obj: date }, //{ obj: undefined },
                            { modal: modal, calendar: calendar },
                            auth
                          )
                      : "";
                  }}
                  title="Edytuj"
                >
                  <img
                    src={
                      Number(auth?.basic) < 2 &&
                      Number(auth?.settlements) < 2 &&
                      Number(auth?.agenda) < 2
                        ? viewIcon
                        : editIcon
                    }
                  />
                </div>
                {auth?.delete ? (
                  <div
                    title="Wykreśl"
                    onClick={async () => {
                      if (potential) {
                        const conf = window.confirm(
                          "Czy na pewno chcesz usunąć wstępne wydarzenie?"
                        );
                        if (conf) {
                          // console.log(calendar.update);
                          const result = await setActive(
                            event.id,
                            "1",
                            "eventCalendarPotential"
                          );
                          if (result) {
                            calendar.setUpdate((prev: number) => prev + 1);
                          }
                          // console.log(calendar.update);
                        }
                      } else {
                        modal.setModal({
                          show: true,
                          heading: "Potwierdź wykreślenie wydarzenia",
                          content: (
                            <div style={{ padding: "16px", width: "100%" }}>
                              <div>
                                <div>
                                  <div>Typ:</div>
                                  <strong>{event.event_string}</strong>
                                </div>
                                <div>
                                  <div>Klient:</div>
                                  <strong>
                                    {event.company_name ||
                                      event.first_name + " " + event.last_name}
                                  </strong>
                                </div>
                                <div>
                                  <div>Dni:</div>
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {view?.values?.dates?.map((d: any) => (
                                      <div style={{ marginRight: "10px" }}>
                                        {prettyDate(d.date, "", true)}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div>
                                  <div>Sale:</div>
                                  <div style={{ fontWeight: "bold" }}>
                                    {view?.values?.venues?.map((v: any) => (
                                      <div>{v}</div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <p>Wydarzenie zostanie przeniesione do kosza.</p>
                              <button
                                className="button"
                                onClick={async () => {
                                  setActive(event.id, "1", "eventCalendar");
                                  calendar.setUpdate(
                                    (prev: number) => prev + 1
                                  );
                                  modal.setModal((prev: any) => ({
                                    ...prev,
                                    show: false,
                                  }));
                                }}
                              >
                                USUŃ
                              </button>
                            </div>
                          ),
                        });
                      }
                    }}
                  >
                    <img src={crossIcon} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="eventContent">
          <div className="mainEventBar">
            <div
              className="eventTimes"
              title={
                event?.id +
                " / " +
                (event?.settled ? "rozliczony" : "nierozliczony")
              }
            >
              {potential ? <>WSTĘPNE</> : ""}
              {view?.values.timeStart && view?.values.timeEnd ? (
                <>{view?.jsx.times}</>
              ) : view?.values.timeStart ? (
                <>od {view?.values.timeStart}</>
              ) : view?.values.timeEnd ? (
                <>do {view?.values.timeEnd}</>
              ) : (
                <></>
              )}
            </div>
            {view?.values.dayNumeral ? (
              <div className="event-day-number">
                Dzień {view?.values.dayNumeral}
                <span style={{ fontWeight: "300", fontSize: "85%" }}>
                  /{view?.values.daysTotal}
                </span>
              </div>
            ) : (
              <></>
            )}
            <div
              className="venue"
              // onClick={() =>
              //   modal.setModal({
              //     content: <EventViewFull event={event} />,
              //     heading: "Podgląd imprezy",
              //     show: true,
              //     style: {
              //       width: "95vw",
              //       maxWidth: "1500px",
              //       minHeight: "95vh",
              //     },
              //   })
              // }
            >
              {potential ? (
                event?.venue_string
              ) : (
                <>
                  {view?.values?.venues && view.values.venues.length ? (
                    <div className="event-venue-summary">
                      <img src={venueIcon} />
                      {[...new Set(view?.values.venues)].map(
                        (i: any, index: number) => (
                          <div key={i + index} className="event-venue-single">
                            {i?.replace("sala", "")?.toUpperCase()}
                          </div>
                        )
                      )}
                    </div>
                  ) : event?.venue?.string ? (
                    event?.venue_string?.toUpperCase()
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div
              className="eventType"
              style={{
                background: event.color ? event.color : "grey",
              }}
            >
              {event?.event_string?.toUpperCase()}
            </div>
          </div>
          <div className="eventGroup">
            <div className="eventClient">
              <img src={event.is_company === 1 ? businessIcon : personIcon} />
              {event.is_company === 2 ? (
                <div>Hotel AGIT Congress & SPA</div>
              ) : (
                <div className="client-display">
                  <div>
                    {event.is_company === 1 ? event.company_name : <></>}
                  </div>
                  <div>
                    {event.first_name} {event.last_name}
                  </div>
                </div>
              )}
            </div>
            <div style={{ display: "flex" }}>
              {potential ? (
                <></>
              ) : (
                <div className="stand-text">
                  <img src={tvIcon} />
                  <div>{view?.values.standText}</div>
                </div>
              )}

              <div>
                {Number(auth?.settlements) > 0 && view?.values?.depositPaid ? (
                  <DepositIcon view={view} settled={event?.settled} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="eventDetails">
            <div className={"details" + (potential ? " potential" : "")}>
              {potential ? (
                <>
                  <div>{event?.comment ? event?.comment : <>brak opisu</>}</div>
                  <div>
                    <button
                      className="normal-button"
                      onClick={() => {
                        if (auth?.edit) {
                          editor(
                            { ...event, id: undefined, potential: true },
                            { obj: undefined },
                            {
                              modal: modal,
                              calendar: calendar,
                            },
                            auth
                          );
                        }
                      }}
                    >
                      UTWÓRZ PEŁNE WYDARZENIE
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div>
                      <img
                        width={17}
                        style={{
                          marginRight: "3px",
                          marginLeft: "-4px",
                          marginBottom: "-4px",
                        }}
                        src={musicIcon}
                      />{" "}
                      {view?.jsx.music}
                    </div>
                    {view?.jsx.get_dish_type() ? (
                      <div style={{ display: "flex", lineHeight: "14px" }}>
                        <img
                          width={12}
                          style={{ marginRight: "7px" }}
                          src={eatIcon}
                        />{" "}
                        {view?.jsx.get_dish_type()}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="schedule">{view?.jsx.schedule}</div>
                  <div>
                    <EventService view={view} auth={auth} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {auth?.export ? (
          <div className="options2">
            <AgendaGenerateButtons
              event={event}
              date={date}
              modal={modal}
              view={view}
              auth={auth}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="eventComments">
        {view?.values.comments.reverse().map((c: any) => (
          <div key={(c.id ? c.id : c.guid) + "_" + date}>
            <img src={infoIcon} />
            <span>{c.content}</span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}
