import insertGeneric from "../dataFetch/insertGeneric";
import VoucherList from "./VoucherList";
import VoucherListGroups from "./VoucherListGroups";
import VoucherListGroupView from "./VoucherListGroupView";

export default function VoucherAddFinalize({
  setFinalize,
  setUpdate,
  auth,
  items,
  userID,
  groups,
  setGroups,
}: {
  setFinalize: Function;
  setUpdate: Function;
  auth: any;
  items: any;
  groups: any;
  userID: any;
  setGroups: Function;
}) {
  return (
    <div className="voucher-finalize voucher-register">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "700px",
          }}
        >
          <button
            className="normal-button"
            style={{ width: "max-content" }}
            onClick={() => setFinalize(false)}
          >
            Powrót
          </button>

          <button
            onClick={async () => {
              insertGeneric("set-voucher-register", {
                print_user_id: userID,
              });
              //clear working memory
              await insertGeneric("set-voucher-memory", {
                print_user_id: userID,
              });
              console.log({
                vouchers: groups,
              });
              await setUpdate((prev: number) => prev + 1);
              await setGroups([]);
            }}
            className="normal-button green"
          >
            Dodaj do rejestru jako wydrukowane
          </button>
        </div>
        <div
          className="scrollable-70 voucher-register-groups"
          style={{ display: "block" }}
        >
          <VoucherListGroups
            auth={auth}
            loading={false}
            items={items}
            setUpdate={setUpdate}
            currentItem={{}}
            setCurrentItem={() => {}}
            heading={false}
          />
        </div>
        {/* <VoucherList
          setUpdate={setUpdate}
          auth={auth}
          items={items}
          heading={"Przegląd"}
        /> */}

        <div
          style={{
            fontSize: "10px",
            fontFamily: "monospace",
            maxWidth: "600px",
          }}
        ></div>
      </div>
      <pre
        className="scrollable-70"
        style={{
          fontSize: "9px",
          background: "black",
          padding: "16px",
          color: "grey",
        }}
      >
        {JSON.stringify(items, null, 2)}
      </pre>
    </div>
  );
}
