import { useState } from "react";
import insertGeneric from "../../dataFetch/insertGeneric";
import HolidayCell from "../table/HolidayCell";
import AttEvents from "./AttEvents";
import minutesToJSX from "../../functions/minutesToJSX";
import SumRow from "./SumRow";

export default function AttRow({
  date,
  holidays,
  holidaysSuggested,
  employeeData,
  location,
  setUpdate,
  setSmallUpdate,
  items,
  selectedPerson,
  index,
  arr,
  timeSum,
  setTimeSum,
  add,
  setAdd,
}: {
  date: any;
  holidays?: any[];
  holidaysSuggested: any[] | undefined;
  employeeData?: any;
  location: string;
  setUpdate: Function;
  setSmallUpdate: Function;
  items: any[];
  selectedPerson: string | false;
  index: number;
  arr: any[];
  timeSum: number;
  setTimeSum: Function;
  add: any;
  setAdd: Function;
}) {
  const { obj: d, holiday } = date;

  const [holidayOptions, setHolidayOptions] = useState(false);

  const datumCor = new Date(d);
  datumCor.setHours(10);
  const dateSQL = datumCor.toISOString().slice(0, 10);

  const isHoliday = holidays
    ? holidays.find((h: any) => h.date === dateSQL)
    : false;

  const isHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.find((h: any) => h.date === dateSQL)
    : false;

  const currentHoliday = holidays
    ? holidays.filter((h: any) => h.date === dateSQL)[0]
    : false;

  const currentHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.filter((h: any) => h.date === dateSQL)[0]
    : false;

  function setHolidayType(holidayType: number) {
    if (employeeData && employeeData.id) {
      insertGeneric("holidays", {
        date: dateSQL,
        employeeID: employeeData.id,
        type: holidayType,
        status: 1,
      });
    }
    setSmallUpdate((prev: number) => prev + 1);
    setHolidayOptions(false);
  }

  const dateIn = items;
  //   const prevIn = arr[index - 1] ? arr[index - 1].items : [];
  //   let nextIn = arr[index + 1] ? arr[index + 1].items : [];
  //   const nextIn2 = arr[index + 2] ? arr[index + 2].items : [];

  return (
    <>
      <tr
        key={d.getDate()}
        className={
          (!dateIn.length ? "empty " : "") +
          (d.getDate() === new Date().getDate() &&
          d.getMonth() === new Date().getMonth() &&
          d.getFullYear() === new Date().getFullYear()
            ? "today"
            : "") +
          (isHoliday ? " holiday" : "") +
          (d.getDay() === 0 ? " weekEnd" : "")
        }
      >
        <td
          className={
            (d.getDay() === 0 ? "sunday" : "") +
            (holiday ? " stateHoliday" : "")
          }
        >
          <div className="date">
            <div className="dayOfWeek">
              {d?.toLocaleDateString("pl-PL", {
                weekday: "short",
              })}
            </div>
            <div className="dayNumber">
              {d?.toLocaleDateString("pl-PL", {
                day: "2-digit",
              })}
            </div>
          </div>
        </td>

        <td>
          <AttEvents
            events={items}
            t={1}
            date={d}
            setUpdate={setUpdate}
            location={location}
            selectedPerson={selectedPerson}
            employeeData={employeeData}
          />
        </td>
        <td>
          <AttEvents
            events={items}
            t={2}
            date={d}
            setUpdate={setUpdate}
            location={location}
            selectedPerson={selectedPerson}
            employeeData={employeeData}
          />
        </td>
        <td>
          {items && items?.length ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {items?.map((i: any) => (
                <SumRow
                  event={i}
                  date={d}
                  timeSum={timeSum}
                  setTimeSum={setTimeSum}
                  add={add}
                  setAdd={setAdd}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </td>
        <td
          //   style={{ display: "block", position: "relative" }}
          className={isHoliday || isHolidaySuggested ? "holiday" : ""} // "empty"}
        >
          <HolidayCell
            isHoliday={isHoliday}
            isHolidaySuggested={isHolidaySuggested}
            currentHoliday={currentHoliday}
            currentHolidaySuggested={currentHolidaySuggested}
            employeeData={employeeData}
            setHolidayOptions={setHolidayOptions}
            setSmallUpdate={setSmallUpdate}
            dateSQL={dateSQL}
          />
        </td>
      </tr>
      {holidayOptions ? (
        <div className="holidayOptions">
          <ul>
            <li onClick={() => setHolidayType(1)}>Urlop wypoczynkowy</li>
            <li onClick={() => setHolidayType(7)}>Chorobowe</li>
            <li onClick={() => setHolidayType(3)}>Urlop okolicznościowy</li>
            <li onClick={() => setHolidayType(6)}>Dzień wolny za święto</li>
            <li onClick={() => setHolidayType(5)}>Opieka nad dzieckiem</li>
            <li onClick={() => setHolidayType(4)}>Urlop bezpłatny</li>
            <li onClick={() => setHolidayType(9)}>Dzień wolny z nadgodzin</li>
            <li onClick={() => setHolidayType(10)}>Urlop - inny</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
