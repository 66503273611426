import EventFullView from "./EventFullView";
import eyeIcon from "../../images/icons/eye.png";
import editIcon from "../../images/icons/pencil.png";
import viewIcon from "../../images/icons/view.svg";
import crossIcon from "../../images/icons/cross.png";
import setActive from "../dataFetch/setActive";
import AddPotentialEvent from "./AddPotentialEvent";
import { useContext } from "react";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";

export default function AgendaEditDeleteButtons({
  auth,
  editor,
  modal,
  date,
  event,
  //   calendar,
  potential,
}: {
  auth: any;
  editor: any;
  modal: any;
  date: any;
  event: any;
  //   calendar: any;
  potential?: Boolean;
}) {
  const calendar = useContext(CalendarContext);
  const { userID } = useContext(UserContext);
  // console.log(userID, auth?.edit, event?.created_by);
  const authorized =
    auth?.edit && (auth?.edit === 2 ? userID === event?.created_by : true);
  return (
    <>
      {authorized ? (
        <>
          <div
            onClick={() => {
              editor(
                { ...event, id: undefined, potential: true },
                { obj: undefined },
                {
                  modal: modal,
                  calendar: calendar,
                },
                auth
              );
            }}
          >
            ^
          </div>
          <img
            src={authorized ? editIcon : viewIcon}
            onClick={() => {
              if (potential) {
                modal.setModal({
                  show: true,
                  heading: "Edycja niepotwierdzonego wydarzenia",
                  style: { width: "750px" },
                  content: (
                    <AddPotentialEvent
                      auth={auth}
                      event={event}
                      date={date}
                      calendar={calendar}
                      setModal={modal.setModal}
                    />
                  ),
                });
              } else {
                editor(
                  event,
                  { obj: undefined },
                  {
                    modal: modal,
                    calendar: calendar,
                  },
                  auth
                );
              }
            }}
          />
          {auth?.delete ? (
            <img
              src={crossIcon}
              onClick={async () => {
                if (potential) {
                  const conf = window.confirm(
                    "Czy na pewno chcesz usunąć wstępne wydarzenie?"
                  );
                  if (conf) {
                    // console.log(calendar.update);
                    const result = await setActive(
                      event.id,
                      "1",
                      "eventCalendarPotential"
                    );
                    if (result) {
                      calendar.setUpdate((prev: number) => prev + 1);
                    }
                    // console.log(calendar.update);
                  }
                } else {
                  modal.setModal({
                    show: true,
                    heading: "Potwierdź wykreślenie wydarzenia",
                    content: (
                      <div style={{ padding: "16px", width: "100%" }}>
                        <div>
                          <div>
                            <div>Typ:</div>
                            <strong>{event.event_string}</strong>
                          </div>
                          <div>
                            <div>Klient:</div>
                            <strong>
                              {event.company_name ||
                                event.first_name + " " + event.last_name}
                            </strong>
                          </div>
                          <div>
                            <div>Data:</div>
                            <strong>
                              {new Date(event.date).toLocaleDateString(
                                "pl-PL",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                }
                              )}
                            </strong>
                          </div>
                          <div>
                            <div>Sala:</div>
                            <strong>{event.venue_string}</strong>
                          </div>
                        </div>
                        <p>Wydarzenie zostanie przeniesione do kosza.</p>
                        <button
                          className="button"
                          onClick={async () => {
                            setActive(event.id, "1", "eventCalendar");
                            calendar.setUpdate((prev: number) => prev + 1);
                            modal.setModal((prev: any) => ({
                              ...prev,
                              show: false,
                            }));
                          }}
                        >
                          USUŃ
                        </button>
                      </div>
                    ),
                  });
                }
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
