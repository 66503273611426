import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import VoucherLSIChanges from "./VoucherLSIchanges";

export function compareVoucherCount(lsiItem: any, happ: any) {
  const name = lsiItem?.nazwa
    ?.replace(/Voucher płatny /, "")
    ?.replace(/Voucher gratis /, "G")
    ?.replace(/zł/, "");
  const countLSI = Math.round(lsiItem?.ilosc_jednostek);
  const countHapp = happ?.length && happ?.filter(
    (h: any) =>
      Number(h?.amount) ===
        Number(lsiItem?.indeks?.split("_")?.at(-1)?.replace("zl", "")) &&
      (lsiItem?.indeks?.split("_")?.[1] === "platny") === Boolean(h?.paid) &&
      h?.status === 2
  )?.length;
  return { countLSI: countLSI, countHapp: countHapp };
}

export default function VoucherCountLSI({
  lsiCount,
  register,
  update,
}: {
  lsiCount: any;
  register: any;
  update: number;
}) {
  const [selectedVoucherType, setSelectedVoucherType] = useState();

  return (
    <div className="lsi-voucher-count">
      <div style={{ color: "whitesmoke", fontSize: "120%" }}>LSI</div>
      {lsiCount &&
        lsiCount?.map((item: any) => {
          const name = item?.nazwa
            ?.replace(/Voucher płatny /, "")
            ?.replace(/Voucher gratis /, "G")
            ?.replace(/zł/, "");
          //   const countLSI = Math.round(item?.ilosc_jednostek);
          //   const countHapp = register?.filter(
          //     (h: any) =>
          //       Number(h?.amount) ===
          //         Number(item?.indeks?.split("_")?.at(-1)?.replace("zl", "")) &&
          //       (item?.indeks?.split("_")?.[1] === "platny") ===
          //         Boolean(h?.paid) &&
          //       h?.status === 2
          //   )?.length;
          const { countLSI, countHapp } = compareVoucherCount(item, register);
          //   console.log(register, countHapp, item);
          return (
            <div
              className={
                "voucher-type" +
                (Number(item?.ilosc_jednostek) === 0 ? " empty" : "") +
                (Number(item?.id) === Number(selectedVoucherType)
                  ? " active"
                  : "")
              }
              onClick={() => setSelectedVoucherType(item?.id)}
            >
              <span>{name}</span>{" "}
              <span
                className={
                  "voucher-units-available" +
                  (countLSI === countHapp ? "" : " conflict")
                }
                title={"hApp: " + countHapp}
              >
                {countLSI}
              </span>
            </div>
          );
        })}
      <div className="absolute-down">
        <VoucherLSIChanges update={update} voucherID={selectedVoucherType} />
      </div>
    </div>
  );
}
