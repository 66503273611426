import { useEffect, useState } from "react";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import AttRow from "./newAttendanceTable/AttRow";
import { isSameDay } from "../schedules/ScheduleTable";
import minutesToJSX from "../functions/minutesToJSX";
import { isWorkingDay } from "../functions/dates";
import { dayDiff } from "../functions/prettyDate";

export default function NewAttendanceTable({
  selectedPerson,
  selectedEmployeeData,
  calendar,
  date,
  update,
  setUpdate,
  setSmallUpdate,
  setActive,
  holidays,
  holidaysSuggested,
  location,
  employees,
}: {
  selectedPerson: string | false;
  selectedEmployeeData: any | false;
  calendar: any;
  date: { month: number; year: number };
  update: number;
  setUpdate: any;
  setSmallUpdate: any;
  setActive: any;
  holidays: any[] | undefined;
  holidaysSuggested: any[] | undefined;
  location: string;
  employees: any;
}) {
  const [timeSum, setTimeSum] = useState(0);
  const [add, setAdd] = useState<any>({});

  useEffect(() => {
    setAdd({});
    setTimeSum(0);
  }, [selectedPerson]);
  console.log(employees);
  const { loading, rows } = useGeneric({
    name: employees?.length ? "attendanceDetails" : "",
    query: {
      id: employees?.find((e: any) => e?.cardname === selectedPerson)?.id, /// enter id here
      year: date.year,
      month: date.month + 1,
    },
    update: selectedPerson + String(update),
  });

  function addItemsToDates(dates: Date[], items: any) {
    return dates.map((d: any) => {
      const datesWithEvents = items.filter(
        (i: any, index: number) =>
          isSameDay(i.t1, d.obj) || isSameDay(i.t2, d.obj)
      );
      return {
        datum: d,
        items: datesWithEvents.length
          ? datesWithEvents
          : {
              person: items && items[0] ? items[0]?.cardname : selectedPerson,
              detailed: {
                carduserid: items && items[0] ? items[0].employeeID : null, //null TEMPORARY, MUST GET THIS DATA
                cardname: items && items[0] ? items[0]?.cardname : null, //null TEMPORARY, MUST GET THIS DATA
              },
            },
      };
    });
  }

  const selectedSums = Object.keys(add)?.filter(
    (k: any) => add?.[k]?.status
  )?.length;

  const attendanceByDate = addItemsToDates(calendar.dates, rows);
  //   console.log(attendanceByDate);

  function checkWorking(i: any) {
    const workingData = isWorkingDay(new Date(i?.t1));
    return !workingData?.holiday && !workingData?.weekend && !i?.holiday_type;
  }

  const allMinutes: any = attendanceByDate
    .map(({ datum, items }) =>
      items && items?.length
        ? items
            ?.filter((i: any) => isSameDay(i?.t1, datum?.obj))
            ?.map((i: any) => Number(i?.minutes))
            ?.reduce((e1: any, e2: any) => e1 + e2, 0)
        : 0
    )
    ?.reduce((a1: number, a2: number) => a1 + a2, 0);

  const allIds: any = {};
  attendanceByDate.forEach(({ datum, items }) => {
    // console.log(datum);
    items &&
      items?.length &&
      items?.forEach((i: any) => {
        const isWorking = checkWorking(i);
        if (i?.minutes > 0)
          allIds[i?.id] = {
            status: true,
            countWorking: isWorking,
            date: new Date(i?.t1),
          };
      });
  });

  const workingDays: any = {};

  Object.keys(add)?.forEach((k: any) => {
    const o = add?.[k];
    if (o?.status === true && o?.countWorking) {
      const d = o?.date?.toISOString()?.slice(0, 10);
      workingDays[d] = true;
    }
  });

  const workingDaysNumber = Object.keys(workingDays)?.filter(
    (k: any) => k
  )?.length;

  const emptyWorkingDays = attendanceByDate.filter(
    ({ datum, items }) =>
      !datum?.holiday &&
      [1, 2, 3, 4, 5].includes(new Date(datum?.obj)?.getDay()) &&
      items?.person &&
      !holidays?.find((h: any) => isSameDay(h?.date, datum?.obj))
      // && items?.length && items?.find((i: any) => isSameDay(i?.t2, datum?.obj))
  );
  const emptyWorkingDaysNumber = emptyWorkingDays?.length;

  console.log("empty", emptyWorkingDays);
  console.log("att_by_d", attendanceByDate);
  console.log("holidays", holidays);

  const gap =
    (workingDaysNumber + emptyWorkingDaysNumber) *
      Number(selectedEmployeeData?.base_hours_per_day ?? 8) *
      60 -
    timeSum;
  const gapJSX = (
    <>
      {gap > 0 ? "-" : gap < 0 ? "+" : ""} {minutesToJSX(Math.abs(gap), false)}
    </>
  );

  return (
    <div>
      <table className="data-table attendanceTable">
        <thead /*onClick={() => generateXLSX(attendanceByDate)}*/>
          <tr>
            <th style={{ width: "60px" }}>Dzień</th>
            <th style={{ width: "80px" }}>Przyjście</th>
            <th style={{ width: "80px" }}>Wyjście</th>
            <th
              style={{
                minWidth: "87px",
                textAlign: "left",
                cursor: "pointer",
                background: timeSum ? "#cfffcf" : undefined,
                fontWeight: timeSum ? "bold" : undefined,
              }}
              onClick={() => {
                if (timeSum !== allMinutes) {
                  setTimeSum(allMinutes);
                  setAdd(allIds);
                } else {
                  setTimeSum(0);
                  setAdd({});
                }
              }}
            >
              Czas pracy
            </th>
            <th style={{ minWidth: "100px", textAlign: "left" }}>Urlop</th>
          </tr>
          {timeSum ? (
            <tr>
              <td colSpan={5} style={{ padding: 0, marginTop: "-1px" }}>
                <div className="sum-calc-tool">
                  <div className="sum-calc-label">
                    ZAZNACZONE ({selectedSums})
                  </div>
                  <div>{minutesToJSX(timeSum, false)}</div>
                  <div
                    className={
                      "sum-calc-gap" + (gap < 0 ? " positive" : " negative")
                    }
                  >
                    {gapJSX}
                  </div>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </thead>
        <tbody>
          {loading ? (
            <Loading />
          ) : (
            attendanceByDate.map(
              ({ datum, items }: any, index: number, arr: any[]) => (
                <AttRow
                  key={datum.obj.getDate()}
                  date={datum}
                  items={items}
                  index={index}
                  selectedPerson={selectedPerson}
                  arr={arr}
                  holidays={holidays}
                  holidaysSuggested={holidaysSuggested}
                  employeeData={selectedEmployeeData}
                  location={location}
                  setUpdate={setUpdate}
                  setSmallUpdate={setSmallUpdate}
                  timeSum={timeSum}
                  setTimeSum={setTimeSum}
                  add={add}
                  setAdd={setAdd}
                />
              )
            )
          )}
        </tbody>
      </table>
    </div>
  );
}
