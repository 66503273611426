import { Link } from "react-router-dom";
import planeIcon from "../../images/icons/plane.svg";

export default function Info({ type }: { type: string }) {
  let content = <></>;
  switch (type) {
    case "unauthorised":
      content = (
        <div>
          <p style={{ color: "darkred", fontSize: "30px" }}>
            Brak odpowiednich uprawnień.
          </p>
          <p>
            <Link to="/">Powrót do ekranu głównego</Link>
          </p>
        </div>
      );
      break;
    case "pickPerson":
      content = (
        <div>
          <p>
            <p>Wskaż osobę, żeby zobaczyć tabelę {">"}</p>
          </p>
        </div>
      );
      break;
    default:
      content = <>{type}</>;
      break;
  }
  return (
    <div className="genericInfo">
      <div>{content}</div>
      <img src={planeIcon} className="back-img" />
    </div>
  );
}
