import { useContext } from "react";
import { ViewContext } from "../../contexts/ViewContext";
import GenericForm from "../../forms/GenericForm";
import { UserContext } from "../../contexts/UserContext";
import insertGeneric from "../../dataFetch/insertGeneric";

export default function AttTime({
  e,
  t,
  begin,
  end,
  date,
  location,
  setUpdate,
  setActive,
}: {
  e: any;
  t: number;
  begin: any;
  end: any;
  date: Date;
  location: string;
  setUpdate: Function;
  setActive: Function;
}) {
  const {
    id,
    id2,
    t1,
    t2,
    begin_type,
    end_type,
    mod_data,
    cardname,
    employeeID,
    custom,
    source1,
    source2,
    modified_by,
  } = e;
  const { setModal } = useContext(ViewContext);
  const { permissions } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);

  const ip = mod_data ? mod_data?.split("/")[0] : null;
  const dateModified = mod_data
    ? new Date(mod_data?.split("/")[1] * 1000).toLocaleString("pl-PL")
    : null;
  const source_db = t === 1 ? source1 : source2;

  function changeEntryActiveStatus(item: any) {
    const idFromField =
      item.parentNode.parentNode.querySelector("#id").textContent;

    const activeFromField =
      item.parentNode.parentNode.querySelector("#active").textContent;

    const idValue = t === 1 ? id : id2;

    setActive(
      idValue,
      parseInt(activeFromField),
      source_db === "attendance"
        ? "attendance"
        : source_db === "attendance_3maja"
        ? "attendance_3maja"
        : source_db === "attendance_custom"
        ? "attendance_custom"
        : source_db === "attendance_3maja_custom"
        ? "attendance_3maja_custom"
        : null
      //   location === "hotel"
      //     ? "attendance"
      //     : location === "biuro"
      //     ? "attendance_3maja"
      //     : null
    );
    setUpdate((prev: number) => prev + 1);
  }
  return (
    <div
      className={
        "attendanceTime" +
        (mod_data?.match(/\/typ$/)
          ? " amended-silent"
          : custom || mod_data || modified_by
          ? " amended"
          : "") +
        (source_db === "attendance" && location === "biuro" ? " hotel" : "")
      }
      title={
        (custom
          ? "Zmienione przez użytkownika nr " +
            e.modified_by +
            " / IP: " +
            ip +
            " / " +
            dateModified
          : "Zapis z urządzenia") +
        "\r\n" +
        source_db
      }
      onClick={() =>
        !(p > 3)
          ? undefined
          : setModal({
              show: true,
              heading: "Edytuj wpis",
              content: (
                <GenericForm
                  formContent={[
                    {
                      name: "",
                      label: "Osoba",
                      initialValue: "",
                      jsx: (
                        <div>
                          <div
                            style={{
                              fontWeight: "500",
                              fontSize: "18px",
                            }}
                          >
                            {cardname}
                          </div>
                        </div>
                      ),
                    },
                    {
                      name: "",
                      label: "Czas",
                      initialValue: "",
                      jsx: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                          }}
                        >
                          <div id="id" style={{ display: "none" }}>
                            {e.id}
                          </div>
                          <div id="active" style={{ display: "none" }}>
                            {e.active}
                          </div>
                          <div style={{ fontSize: "16px" }}>
                            {date.toLocaleDateString("pl-PL")}
                          </div>
                          <div
                            style={{
                              fontSize: "20px",
                              marginLeft: "9px",
                              fontFamily: "monospace",
                            }}
                          >
                            {(t === 1 ? t1 : t2)?.split(" ")[1].slice(0, 5)}
                          </div>
                          <div style={{ display: "none" }}>{cardname}</div>
                          <div style={{ display: "none" }}>{employeeID}</div>
                        </div>
                      ),
                    },
                    {
                      name: "delete",
                      initialValue: "",
                      label: "Usuwanie wpisu",
                      jsx: (
                        <button
                          onClick={(e) => {
                            setModal((prev: any) => ({
                              ...prev,
                              show: false,
                            }));
                            changeEntryActiveStatus(e.target);
                          }}
                          type="button"
                          className="button"
                          style={{
                            padding: "5px 12px",
                            width: "auto",
                            fontSize: "14px",
                          }}
                        >
                          USTAW JAKO NIEAKTYWNY
                        </button>
                      ),
                    },
                    {
                      name: "time",
                      initialValue:
                        t === 1 ? t1?.slice(11, 16) : t2?.slice(11, 16),
                      label: "Zmiana godziny",
                      element: "input",
                      inputType: "time",
                    },
                    {
                      name: "date",
                      initialValue:
                        t === 1 ? t1?.slice(0, 10) : t2?.slice(0, 10),
                      label: "Zmiana daty",
                      element: "input",
                      inputType: "date",
                    },

                    {
                      name: "typeshort",
                      initialValue: t === 1 ? begin_type : end_type,
                      label: "Rodzaj wpisu",
                      element: "select",
                      autoFocus: true,
                      optionsList: [
                        {
                          id: 0,
                          name: "przyjście do pracy",
                          active: true,
                        },
                        {
                          id: 1,
                          name: "wyjście z pracy",
                          active: true,
                        },
                        {
                          id: 2,
                          name: "ZDALNIE - początek pracy",
                          active: true,
                        },
                        {
                          id: 3,
                          name: "ZDALNIE - koniec pracy",
                          active: true,
                        },
                        {
                          id: 4,
                          name: "INNE - początek pracy",
                          active: true,
                        },
                        {
                          id: 5,
                          name: "INNE - koniec pracy",
                          active: true,
                        },
                      ],
                    },
                  ]}
                  onSubmit={async (values: any) => {
                    // console.log(values, pair.person);
                    //datumCor.toISOString().slice(0, 10)
                    await insertGeneric("update-attendance-type", {
                      //   timestamp: e.timestamp,
                      typeshort: values.typeshort,
                      //   custom: custom,
                      source_db: source_db,
                      id: t === 1 ? id : id2,
                      time: values.time,
                      date: values.date,
                      timestamp:
                        new Date(values.date + " " + values.time)?.getTime() /
                        1000,
                    });
                    setUpdate((prev: number) => prev + 1);
                    setModal((prev: any) => ({
                      ...prev,
                      show: false,
                    }));
                  }}
                />
              ),
            })
      }
    >
      {t === 1 ? begin : end}
    </div>
  );
}
