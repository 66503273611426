import FieldWrap from "./FieldWrap";
import { formContent, formField, formFull } from "../formContentType";
import { useState } from "react";

export default function FieldSet({
  schema,
  schemaOptions,
  tabSpecific,
  setTab,
  subItem,
  setSubItem,
  defaultsSet,
  display,
  readOnly,
}: {
  schemaOptions?: formFull;
  schema: formContent;
  tabSpecific?: string;
  setTab?: Function;
  subItem?: string;
  setSubItem?: Function;
  defaultsSet?: any;
  display: Boolean;
  readOnly?: Boolean;
}) {
  //const [subItem, setSubItem] = useState("");
  //console.log(defaultsSet);

  return (
    <fieldset
      className={
        schemaOptions?.className?.match("area-layout")
          ? "multiColumn" + " " + schemaOptions.className
          : "multiColumn columns-" +
            schema.length +
            (schemaOptions?.className ? " " + schemaOptions.className : "")
      }
      style={{
        display: !display ? "none" : undefined,
      }}
    >
      {schema.map((subset: any, index: number) => {
        const subsetItems = subset.content ? subset.content : subset;
        return (
          <div
            key={index + (subItem ? subItem : "")}
            className="formColumn"
            style={subset?.blockStyle}
          >
            {subsetItems &&
              subsetItems.length &&
              subsetItems?.map((f: formField) => (
                <FieldWrap
                  key={tabSpecific ? f.name + "%" + tabSpecific + "%" : f.name}
                  schema={{
                    ...f,
                    name: tabSpecific
                      ? f.name + "%" + tabSpecific + "%"
                      : f.name,
                    subItem: subItem,
                    setSubItem: setSubItem,
                    subDefaults: defaultsSet,
                    setTab: setTab,
                    readOnly: readOnly !== undefined ? readOnly : f?.readOnly,
                    tabSpecific: tabSpecific,
                  }}
                />
              ))}
          </div>
        );
      })}
    </fieldset>
  );
}
