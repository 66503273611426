import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import { calculateWorkingDays } from "../functions/dates";
import minutesToJSX from "../functions/minutesToJSX";

export default function AttendanceSumYearHeadless({
  year,
  employeeID,
}: {
  year: number;
  employeeID: any;
}) {
  const thisYear = new Date().getFullYear();
  const yesterday = new Date();
  yesterday.setDate(yesterday?.getDate() - 1);
  const workingDays = calculateWorkingDays(year, {
    from: new Date(year, 0, 1, 12),
    to: thisYear === year ? yesterday : new Date(year, 11, 31, 12),
  });
  const workingDaysNumber = workingDays?.workingDays;

  const { rows, loading } = useGeneric({
    name: "attendanceAnnualSum",
    query: {
      employeeID: employeeID,
      year: year,
      workingDays: workingDaysNumber,
    },
    update: year + employeeID + workingDaysNumber,
  });

  const r = !loading ? rows?.[0] : undefined;

  return loading ? (
    <Loading />
  ) : (
    <div style={{ fontSize:"11px", fontWeight:"bold", color: r?.minutes_balance > 0 ? "green" : "brown" }}>
      {r?.minutes_balance > 0 ? "+" : "-"}{" "}
      {minutesToJSX(Math.abs(r?.minutes_balance), false)}
    </div>
  );
}
