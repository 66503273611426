import { StrictMode, useContext, useEffect, useRef, useState } from "react";
import { formField, multiAddProps } from "../../formContentType";
import MultiAddCell from "./MultiAddCell";

import ChangeOrder from "./ChangeOrder";
import { createGUID } from "../../../functions/tools";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../../Form";
import MultiAddInfoCell from "./MultiAddInfoCell";
import MultiAddField from "../MultiAddField";
import MultiAddSubShow from "./MultiAddSubShow";
import MultiAddSubShow2 from "./MultiAddSubShow2";
import { UserContext } from "../../../contexts/UserContext";
import MultiAddSubEngine from "./MultiAddSubEngine";

export default function MultiAddTableRow({
  entry,
  index,
  columns,
  entries,
  setEntries,
  multiAddProps,
  usedUniqueOptions,
  setUsedUniqueOptions,
  topSchema,
  uniqueID,
  subData,
}: {
  entry: any;
  index: number;
  columns: any;
  entries: any;
  setEntries: any;
  multiAddProps?: multiAddProps;
  usedUniqueOptions: any;
  setUsedUniqueOptions: Function;
  topSchema?: any;
  uniqueID?: number | string;
  subData?: any;
}) {
  const { userID } = useContext(UserContext);
  const [rowValues, setRowValues] = useState(entry);
  const [prevRowValues, setPrevRowValues] = useState({});
  const [rowStyle, setRowStyle] = useState(() => {
    const columnKeys = Object.keys(columns);
    let result = {};
    columnKeys?.forEach((key: any) => {
      const optionList = columns?.[key]?.optionsList;
      const optionsListItem =
        optionList &&
        Array.isArray(optionList) &&
        optionList?.find((o: any) => o?.id == entry?.[key]);
      if (optionsListItem) {
        const style = optionsListItem?.rowStyle;
        result = style;
      }
    });
    return result;
  });
  // console.log(columns, entry);

  const [rowTouched, setRowTouched] = useState(0);

  const { getValues, setValue } = useFormContext();

  const { data } = useContext(FormDataContext);

  const sortBaseElement = useRef();

  useEffect(() => {
    let n = entries.map((e: any, i: number) =>
      i === index
        ? multiAddProps?.order
          ? { ...rowValues, order: index }
          : rowValues
        : e
    );

    setEntries((prev: any[]) => n);
  }, [JSON.stringify(rowValues)]);

  //SORTING
  // useEffect(() => {
  //   const wait = setTimeout(() => {
  //     setEntries((prev: any[]) => {
  // if (multiAddProps?.sort?.callBack) {
  //   let mod;
  //   prev.sort(multiAddProps.sort.callBack);
  //   mod = prev.map((item: any) => ({ ...prev, /*guid: createGUID()*/ }));
  // }
  // return prev;
  //     });
  //   }, 240);
  //   return () => clearTimeout(wait);
  // }, [JSON.stringify(rowValues)]);

  useEffect(() => {
    if (multiAddProps?.changeEffect) {
      multiAddProps.changeEffect(
        getValues(),
        data,
        prevRowValues,
        rowValues,
        setValue
      );
    }
  }, [JSON.stringify(rowValues)]);

  const subShow = multiAddProps && multiAddProps.subData; //&& multiAddProps?.subData(rowValues);

  const columnKeys = Object.keys(columns);
  const regularColumnKeys = columnKeys.filter(
    (k: string) => !columns[k]?.fullRow
  );
  const fullRowColumnKeys = columnKeys.filter(
    (k: string) => columns[k]?.fullRow
  );

  function makeColumn(columnKey: any, settings: any) {
    return columns[columnKey].info ? (
      <MultiAddInfoCell
        render={columns[columnKey].render}
        rowValues={rowValues}
        schema={columns[columnKey]}
        topSchema={topSchema}
      />
    ) : (
      <MultiAddCell
        key={columnKey + (uniqueID ? uniqueID : entry?.id || entry?.guid)}
        colSpan={settings?.colSpan}
        entries={entries}
        setEntries={setEntries}
        schema={columns[columnKey]}
        fieldValue={rowValues[columnKey]}
        sortCell={multiAddProps?.sort?.cell}
        fieldName={columnKey}
        rowIndex={index}
        rowValues={rowValues}
        setRowValues={setRowValues}
        setPrevRowValues={setPrevRowValues}
        multiAddProps={multiAddProps}
        usedUniqueOptions={usedUniqueOptions}
        setUsedUniqueOptions={setUsedUniqueOptions}
        rowTouched={rowTouched}
        setRowTouched={setRowTouched}
        topSchema={topSchema}
        setRowStyle={setRowStyle}
      />
    );
  }

  function removeItem() {
    if (window.confirm("Czy na pewno chcesz usunąć pozycję?")) {
      setEntries((prev: any) =>
        prev.filter((item: any) =>
          item.guid
            ? item.guid !== entry.guid
            : item.id
            ? item.id !== entry.id
            : true
        )
      );
    }
  }

  return multiAddProps?.compact ? (
    <div className="multi-item-compact" key={entry.guid} style={rowStyle}>
      {regularColumnKeys.map(makeColumn)}
      {!topSchema?.readOnly && multiAddProps?.add !== false ? (
        <div className="compact-remove" onClick={removeItem}>
          ✖
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <>
      {entry.category ? <tr></tr> : <></>}
      <tr key={entry.guid} style={rowStyle}>
        {multiAddProps && multiAddProps?.reorderable === false ? (
          <td></td>
        ) : (
          <ChangeOrder
            index={index}
            length={entries.length}
            entries={entries}
            setEntries={setEntries}
            orderSave={multiAddProps?.order}
          />
        )}
        {regularColumnKeys.map(makeColumn)}
        {!topSchema?.readOnly && multiAddProps?.add !== false ? (
          <td
            onClick={removeItem}
            style={{ cursor: "pointer", padding: "0 9px" }}
          >
            ✖
          </td>
        ) : (
          <td></td>
        )}
      </tr>
      {fullRowColumnKeys ? (
        fullRowColumnKeys.map((k: any) => (
          <tr className="full-row">
            <th colSpan={3}>{columns[k]?.name}</th>
            {makeColumn(k, { colSpan: regularColumnKeys.length - 1 })}
          </tr>
        ))
      ) : (
        <></>
      )}
      {subShow ? (
        <>
          {userID === 1 && false ? (
            <MultiAddSubShow2
              key={index + "-" + (entry?.id || entry?.guid)}
              data={data}
              subShow={subShow}
              regularColumnKeys={regularColumnKeys}
              topSchema={topSchema}
              rowValues={rowValues}
            />
          ) : (
            <></>
          )}
          {/* <MultiAddSubEngine
            key={index + "-" + (entry?.id || entry?.guid)}
            data={data}
            subShow={subShow}
            regularColumnKeys={regularColumnKeys}
            topSchema={topSchema}
            rowValues={rowValues}
            entries={entries}
          /> */}
          {/* <hr/>\ */}

          <MultiAddSubShow
            key={index + "-" + (entry?.id || entry?.guid)}
            data={data}
            subShow={subShow}
            regularColumnKeys={regularColumnKeys}
            topSchema={topSchema}
            rowValues={rowValues}
            // entries={entries}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
