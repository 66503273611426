import minutesToJSX, { minutesToString } from "../../functions/minutesToJSX";
import { isSameDay } from "../../schedules/ScheduleTable";
import homeIcon from "../../../images/icons/home2.svg";
import { useState } from "react";
import { isWorkingDay } from "../../functions/dates";

export default function SumRow({
  event,
  date,
  timeSum,
  setTimeSum,
  add,
  setAdd,
}: {
  event: any;
  date: Date;
  timeSum: number;
  setTimeSum: Function;
  add: any;
  setAdd: Function;
}) {
  const {
    id,
    t1,
    t2,
    begin_type,
    end_type,
    pair_type,
    group,
    minutes,
    holiday_type,
  } = event;

  const showDuration = isSameDay(t1, date) || (!t1 && t2);

  const dayBeforeEnd = new Date(t2);
  dayBeforeEnd.setDate(0);

  const overnightEndOnFirst =
    t1 &&
    t2 &&
    new Date(t1).getDate() === dayBeforeEnd.getDate() &&
    new Date(t2).getDate() === 1 &&
    date.getDate() === 1
      ? true
      : false;

  const durationText = minutesToString(event?.minutes, true);

  let customMinutes = 0;
  let extraTimeString = "";
  if (group === 1) {
    customMinutes = minutes > 12 * 60 ? 12 * 60 : minutes;
    extraTimeString = minutesToString(minutes - customMinutes, false);
  }

  const workingData = isWorkingDay(new Date(t1));
  const isWorking =
    !workingData?.holiday && !workingData?.weekend && !holiday_type;

  const deselect =
    add?.[id]?.status === undefined ? false : add?.[id]?.status === true;

  return (
    <div
      title={`${t1}\r\n${t2}\r\n${durationText}`}
      style={{
        minHeight: "22px",
        display: "flex",
        alignItems: "center",
        background: isWorking || !isSameDay(t1, date) ? "initial" : "#d1e5f3",
        width: isWorking || !isSameDay(t1, date)? "initial" : "max-content",
        padding: isWorking || !isSameDay(t1, date)? "0" : "0 4px",
        borderRadius: "5px",
      }}
    >
      {overnightEndOnFirst ? (
        <span
          style={{
            display: "inline-block",
            marginRight: "3px",
            opacity: 0.25,
          }}
        >
          ←
        </span>
      ) : (
        <></>
      )}
      {showDuration || overnightEndOnFirst ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            opacity: overnightEndOnFirst ? 0.25 : 1,
            cursor: "pointer",
            color: deselect ? "green" : "black",
            border: deselect ? "1px solid green" : "none",
            padding: deselect ? "0px 5px" : "0",
            borderRadius: "5px",
            userSelect: "none",
          }}
          onClick={() => {
            if (!deselect) {
              setTimeSum((prev: any) => Number(prev) + Number(minutes));
              setAdd((prev: any) => ({
                ...prev,
                [id]: {
                  status: true,
                  countWorking: isWorking,
                  date: new Date(t1),
                },
              }));
            } else {
              setTimeSum((prev: any) => Number(prev) - Number(minutes));
              setAdd((prev: any) => ({
                ...prev,
                [id]: { ...prev[id], status: false },
              }));
            }
          }}
        >
          <div>
            {customMinutes ? (
              <>
                {minutesToString(customMinutes, true)}
                <>
                  {extraTimeString && (
                    <span
                      style={{
                        display: "inline-block",
                        marginLeft: "5px",
                        fontSize: "80%",
                        color: "rgb(140,140,140)",
                      }}
                    >
                      (+{extraTimeString})
                    </span>
                  )}
                </>
              </>
            ) : (
              minutesToJSX(minutes, true, t1)
            )}
          </div>
          {pair_type && pair_type.match(/zdalnie/) ? (
            <img
              src={homeIcon}
              style={{ marginLeft: "4px" }}
              width={17}
              title={"Praca zdalna"}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
