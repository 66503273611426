import { isSameDay } from "../schedules/ScheduleTable";

export function isToday(someDate) {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export function isDate(input) {
  //console.log(input)
  return input instanceof Date && !isNaN(input);
}

export function dayDiff(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  return (d2.getTime() - d1.getTime()) / 24 / 60 / 60 / 1000;
}

export function compareDay(date, fromThis) {
  let compared = new Date();

  compared.setDate(compared.getDate() + fromThis);
  return (
    date.getDate() === compared.getDate() &&
    date.getMonth() === compared.getMonth() &&
    date.getFullYear() === compared.getFullYear()
  );
}

export function timeUntil(dateString, noString = false) {
  if (!dateString || dateString === "0000-00-00") {
    return;
  }
  const now = new Date();
  const date = new Date(dateString);

  const time = now.getTime() - date.getTime();
  const future = time < 0;
  const timeAbs = Math.abs(time);

  const days = timeAbs / 1000 / 60 / 60 / 24;
  const months = days / 30;

  return (
    (future && !noString ? "za " : "") +
    (months > 1 ? Math.round(months) + " mies." : Math.floor(days) + " dni") +
    (!future ? " temu" : "")
  );
}
export function simpleDate(dateString, options, noDate) {
  return (
    (dateString &&
      dateString != "0000-00-00" &&
      new Date(dateString).toLocaleDateString("pl-PL", {
        ...options,
      })) ||
    noDate
  );
}

export function isDateBetween(date, date1, date2) {
  const d = new Date(date);
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (d.getTime() >= d1.getTime() && d.getTime() <= d2.getTime()) {
    return true;
  } else {
    return false;
  }
}

export default function prettyDate(
  dateString,
  todayString = "dziś, ",
  dateOnly,
  options
) {
  if (!dateString) return "";
  const date = new Date(dateString);
  const now = new Date();
  const thisYear = now.getFullYear();
  let tomorrowString = todayString ? "jutro, " : false;
  if (dateOnly && todayString) {
    todayString = "dziś";
    tomorrowString = "jutro";
  }
  let alwaysYear = false;
  if (options?.year === "always") {
    alwaysYear = true;
    delete options.year;
  }

  const normalDate = date.toLocaleString("pl-PL", {
    day: "numeric",
    month: !dateOnly ? "short" : "long",
    hour: !dateOnly ? "numeric" : undefined,
    minute: !dateOnly ? "numeric" : undefined,
    year: date.getFullYear() !== thisYear || alwaysYear ? "numeric" : undefined,
    ...options,
  });

  return todayString !== "" && isToday(date)
    ? todayString +
        (!dateOnly
          ? date.toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "numeric",
            })
          : "")
    : compareDay(date, 1)
    ? tomorrowString
      ? !dateOnly
        ? date.toLocaleTimeString("pl-PL", {
            hour: "numeric",
            minute: "numeric",
          })
        : tomorrowString
      : normalDate
    : normalDate;
}

export function dateString(date, options) {
  const thisYear = new Date().getFullYear();
  const isThisYear = thisYear === new Date(date).getFullYear();
  let alwaysYear = false;
  if (options?.year) {
    alwaysYear = options?.year === "always";
    delete options?.year;
  }

  return options?.short
    ? date.toLocaleString("pl-PL", { day: "numeric" })
    : date.toLocaleString("pl-PL", {
        day: "numeric",
        month: "long",
        year: !isThisYear || alwaysYear ? "numeric" : undefined,
        ...options,
      });
}

export function prettyDateRange(d1, d2, options) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);
  const sameMonth =
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();
  const sameDate = isSameDay(date1, date2);
  const thisYear = new Date().getFullYear();
  const isThisYear =
    thisYear === date1.getFullYear() && thisYear === date2.getFullYear();
  return options?.format === "text" ? (
    `${dateString(date1, { ...options, short: sameMonth })} – ${dateString(
      date2,
      { ...options }
    )}`
  ) : (
    <>
      {sameDate ? (
        <span>{dateString(date1, { ...options })}</span>
      ) : (
        <>
          <span>{dateString(date1, { ...options, short: sameMonth })}</span>
          <span> - </span>
          <span>{dateString(date2, { ...options })}</span>
        </>
      )}
    </>
  );
}
