import { useContext, useEffect, useState } from "react";
import CalendarMonth from "../calendar/CalendarMonth";
import CalendarWeek from "../calendar/CalendarWeek";
import Day from "../calendar/Day";
import generateCalendarData from "../calendar/generateCalendarData";
import { CalendarContext } from "../contexts/CalendarContext";
import useGeneric from "../dataFetch/useGeneric";
import StateButton from "./buttons/StateButton";
import eyeIcon from "../../images/icons/eye.png";
import CalendarControls from "../common/CalendarControls";
import CalendarList from "../calendar/CalendarList";
import Search from "../calendar/Search";
import Loading from "../common/Loading";
import CalendarSidePanel from "../calendar/CalendarSidePanel";
import CalendarTV from "../calendar/CalendarTV";
import { ViewContext } from "../contexts/ViewContext";
import { UserContext } from "../contexts/UserContext";
import CalendarTVexposed from "../calendar/CalendarTVexposed";
import AgendaView from "../calendar/AgendaView";
import CalendarTable from "../calendar/CalendarTable";
import CalendarHistory from "../calendar/CalendarHistory";
import { useParams } from "react-router-dom";
import CalendarReport from "../calendar/CalendarReport";
import { calculateWorkingDays } from "../functions/dates";

export default function Calendar() {
  const [date, setDate] = useState({
    month: new Date().getMonth(),
    week: 1,
    year: new Date().getFullYear(),
  });
  const { currentPage, setCurrentPage, modal } = useContext(ViewContext);

  //month
  const [expandMonth, setExpandMonth] = useState(
    localStorage?.getItem?.("calendar_month_expanded") === "true" ? true : false
  );

  // const { event_id } = useParams();

  // const [verticalTV, setVerticalTV] = useState(true);
  const { userID } = useContext(UserContext);
  setCurrentPage("calendar");

  const [update, setUpdate] = useState(0);
  const [formShow, setFormShow] = useState(false);

  // local storage
  const storedMode: string | null = localStorage.getItem("calendar_mode");

  const [mode, setMode] = useState<
    | "month"
    | "week"
    | "day"
    | "list"
    | "table"
    | "report"
    | "search"
    | "tv"
    | "kitchen"
    | string
  >(storedMode ? storedMode : "list");

  const [creatorHighlight, setCreatorHighlight] = useState(false);

  const [leftPanelExpanded, setLeftPanelExpanded] = useState(false);

  const data = useGeneric({
    name: "event-calendar",
    limit: 1000,
    query: {
      month: date.month + 1,
      year: date.year,
    },
    update: update,
    reload: true,
    secondsToReload: 90,
  });

  const fetchPotentialEvents = useGeneric({
    name: "event-calendar-potential",
    query: {
      month: date.month + 1,
      year: date.year,
    },
    update: update,
    reload: true,
    secondsToReload: 90,
  });

  const potentialEvents =
    !fetchPotentialEvents.loading && fetchPotentialEvents?.rows;

  const formData = useGeneric({
    name: ["events", "venues"],
    limit: 100,
    update: formShow || mode === "search",
  });

  const getAuthData = useGeneric({
    name: "calendar_auth",
    limit: 1000,
    query: {
      user_id: userID,
    },
    update: modal.show || 0,
  });
  const authData =
    !getAuthData.loading && getAuthData.rows && getAuthData.rows[0];

  useEffect(() => {
    const reload = setInterval(
      () => setUpdate((prev: number) => prev + 1),
      15000
    );
    return () => clearInterval(reload);
  }, []);

  const cal = generateCalendarData(
    { month: date.month, year: date.year },
    data,
    potentialEvents
  );
  const calWeek = generateCalendarData(date, data, potentialEvents);
  // console.log(cal, calWeek);

  return authData && !authData.view ? (
    <div style={{ padding: "50px" }}>
      Nie masz uprawnień do korzystania z kalendarza imprez
    </div>
  ) : (
    authData && (
      <CalendarContext.Provider
        value={{
          update: update,
          setUpdate: setUpdate,
          setFormShow: setFormShow,
          formData: formData,
          mode: mode,
          creatorHighlight: creatorHighlight,
          auth: authData,
        }}
      >
        <div
          className={
            "calendarWrapper" +
            (leftPanelExpanded ? " expanded-panel" : "") +
            (mode === "report" ? " report" : "")
          }
        >
          <CalendarSidePanel
            expanded={leftPanelExpanded}
            setExpanded={setLeftPanelExpanded}
            mode={mode}
            setMode={setMode}
            expandedMonth={expandMonth}
            setExpandMonth={setExpandMonth}
          />
          <div className="calendar-main-panel">
            {mode !== "tv" &&
            mode !== "kitchen" &&
            mode !== "history" &&
            mode !== "search" &&
            mode !== "report" ? (
              <CalendarControls
                date={date}
                cal={cal}
                calWeek={calWeek}
                dateSetter={setDate}
                items={data}
                mode={mode}
              />
            ) : (
              <></>
            )}
            {data.loading ? (
              <Loading />
            ) : (
              <>
                {mode === "month" ? (
                  <CalendarMonth cal={cal} expand={expandMonth} />
                ) : mode === "week" ? (
                  <CalendarWeek cal={calWeek} />
                ) : mode === "list" ? (
                  <CalendarList cal={cal} />
                ) : mode === "table" ? (
                  <CalendarTable cal={cal} />
                ) : mode === "report" ? (
                  <CalendarReport cal={cal} />
                ) : mode === "tv" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "2vw",
                    }}
                  >
                    <iframe
                      src="/calendar-tv"
                      style={{ height: "80dvh", aspectRatio: "9/16" }}
                    />
                  </div>
                ) : // <div
                //   className={
                //     "tv-preview-wrapper" + (verticalTV ? " vertical" : "")
                //   }
                // >
                //   <div
                //     className="tv-preview-vertical-switch"
                //     onClick={() => setVerticalTV((prev: Boolean) => !prev)}
                //   >
                //     {verticalTV ? "9/16" : "16/9"}
                //   </div>
                //   <CalendarTVexposed />
                // </div>
                mode === "kitchen" ? (
                  <AgendaView auth={authData} />
                ) : mode === "search" ? (
                  <Search />
                ) : mode === "history" ? (
                  <CalendarHistory auth={authData} />
                ) : (
                  <>Dzień</>
                )}
              </>
            )}
          </div>
        </div>
      </CalendarContext.Provider>
    )
  );
}
