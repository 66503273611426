import { timeUntil } from "../functions/prettyDate";
import { voucherStatus } from "./voucherStatus";

export default function VoucherListGroupSingle({
  v,
  currentItem,
  setCurrentItem,
}: {
  v: any;
  currentItem: any;
  setCurrentItem: Function;
}) {
  const { id, number_text, status, valid_until, paid, number, amount } = v;

  const statusObject = voucherStatus?.[status];

  return (
    <div
      className={
        "voucher-single" +
        (currentItem?.id === v?.id ? " open" : "") +
        (" " + statusObject?.className)
      }
      onClick={() => setCurrentItem(v)}
    >
      <div className="voucher-code" title={id}>
        {number_text || (paid ? "" : "G") + amount + "-" + number}
        <span className={"status-box" + " " + statusObject?.className}></span>
      </div>
      <div className={"status"} title={id}>
        {statusObject?.textLong}
      </div>
      <div style={{ fontSize: "9px", color: "grey" }} title={valid_until}>
        {">"} {timeUntil(valid_until, true)}
      </div>
    </div>
  );
}
