import { useContext } from "react";
import { isSameDay } from "../../schedules/ScheduleTable";

import { ViewContext } from "../../contexts/ViewContext";
import { UserContext } from "../../contexts/UserContext";
import AddMissingNew from "./AddMissingNew";
import AttTime from "./AttTime";
import setActive from "../../dataFetch/setActive";

export default function AttEvent({
  event,
  t,
  date,
  setUpdate,
  location,
}: {
  event: any;
  t: number;
  date: Date;
  setUpdate: Function;
  location: string;
}) {
  const viewContext = useContext(ViewContext);
  const { permissions } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);

  const { t1, t2, begin_type, end_type } = event;

  const begin =
    t1 && isSameDay(t1, date)
      ? new Date(t1)?.toLocaleTimeString().slice(0, 5)
      : "";
  const end =
    t2 && isSameDay(t2, date)
      ? new Date(t2)?.toLocaleTimeString().slice(0, 5)
      : "";

  const dayBefore = new Date(date);
  dayBefore.setDate(dayBefore.getDate() - 1);

  const beganYesterday = t2 && isSameDay(t2, date) && isSameDay(t1, dayBefore);

  const type = t === 1 ? begin_type : end_type;

  return (
    <div
      style={{
        color:
          type === 2
            ? "#77b7af"
            : type === 3
            ? "rgb(89, 127, 122)"
            : type === 4
            ? "rgb(226, 81, 162)"
            : type === 5
            ? "rgb(246, 65, 166)"
            : "inherit",
        minHeight: "22px",
      }}
      className="attendanceTime"
    >
      {t === 1 && begin ? (
        <span
          style={{
            marginRight: "3px",
            fontSize: "9px",
          }}
        >
          ▲
        </span>
      ) : (
        <></>
      )}
      {t === 2 && beganYesterday ? (
        <div
          style={{
            /*fontWeight: "500",
                  fontSize: "9px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  position: "absolute",
                  right: "91%",
                  rotate: "180deg",*/
            position: "absolute",
            right: "calc(100% + 17px)",
            width: "1.5px",
            height: "28px",
            rotate: "-52deg",
            opacity: ".54",
            background: "#3fb71570",
            //borderTop:"3px solid green",
            borderBottom: "3px solid red",
            top: "-16px",
          }}
        >
          {
            //"⤵"
          }
        </div>
      ) : (
        <></>
      )}

      <AttTime
        e={event}
        t={t}
        begin={begin}
        end={end}
        date={date}
        location={location}
        setUpdate={setUpdate}
        setActive={setActive}
      />
      {/* <div className="attendanceTime">{t === 1 ? begin : end}</div> */}

      {t === 2 && end ? (
        <span
          style={{
            marginRight: "3px",
            fontSize: "9px",
          }}
        >
          ▼
        </span>
      ) : (
        <></>
      )}

      <div className="addMissing">
        <AddMissingNew
          event={event}
          date={date}
          defaultEvent={t === 1 ? 0 : 1}
          setUpdate={setUpdate}
          location={location}
        />
      </div>
    </div>
  );
}
