import generateWord from "../docx-generator/generateWord";
import AgendaView from "./AgendaView";
import eventDataToWord from "./eventDataToWord";
import EventFullView from "./EventFullView";
import eyeIcon from "../../images/icons/eye.svg";
import wordIcon from "../../images/icons/new/word.png";
import EditErrorEntry from "../tables/EditErrorEntry";

export default function AgendaGenerateButtons({
  event,
  view,
  date,
  modal,
  auth,
  potential,
}: {
  event: any;
  view: any;
  date: any;
  modal: any;
  auth: any;
  potential?: Boolean;
}) {
  return !potential ? (
    <>
      {auth?.agenda ? (
        <div
          title={"Generuj agendę w pliku Word"}
          onClick={() => {
            const definition = eventDataToWord(event);
            // console.log(definition);
            generateWord(
              definition,
              "agenda",
              "agenda_" +
                event.event_string +
                "_" +
                (view.values.company
                  ? view.values.company.slice(0, 32)
                  : view.values.personOrContact) +
                " (" +
                new Date().toLocaleString().slice(0, 17) +
                ")"
            );
          }}
        >
          <img src={wordIcon} />
          {"A"}
        </div>
      ) : (
        <></>
      )}
      {auth?.settlements ? (
        <div
          title={"Generuj rozliczenie w pliku Word"}
          onClick={() => {
            const definition = eventDataToWord(event);
            generateWord(
              definition,
              "settlement",
              "rozliczenie_" +
                event.event_string +
                "_" +
                (view.values.company
                  ? view.values.company.slice(0, 32)
                  : view.values.personOrContact) +
                " (" +
                new Date().toLocaleString().slice(0, 17) +
                ")"
            );
          }}
        >
          <img src={wordIcon} />
          {"R"}
        </div>
      ) : (
        <></>
      )}
      {auth?.agenda ? (
        <div
          onClick={() => {
            // console.log(event.id, date);
            modal.setModal({
              show: true,
              heading: "Podgląd",
              style: {
                width: "95vw",
              },
              content: <AgendaView id={event.id} date={date} auth={auth} />,
            });
          }}
        >
          <img src={eyeIcon} />
          {""}
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
}
