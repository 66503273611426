import requestUserIcon from "../../images/icons/request_user.svg";
import prettyDate, { timeUntil } from "../functions/prettyDate";
import printIcon from "../../images/icons/print.svg";
import receiveIcon from "../../images/icons/reception.svg";
import numberIcon from "../../images/icons/data-record.svg";
import optionsIcon from "../../images/icons/gear.svg";

import VoucherListGroupSingle from "./VoucherListGroupSingle";
import VoucherDetails from "./VoucherDetails";
import VoucherGroupOptionsList from "./VoucherGroupOptionsList";

export default function VoucherListGroupView({
  group,
  currentItem,
  setCurrentItem,
  options,
  setOptions,
  setUpdate,
}: {
  group: any;
  currentItem: any;
  setCurrentItem: Function;
  options?: number;
  setOptions?: Function;
  setUpdate: Function;
}) {
  const items = group?.items;
  const {
    amount,
    paid,
    request_user,
    valid_until,
    print_date,
    received_date,
    number: first_number,
  } = items?.at(0);
  const last_number = items?.at(-1)?.number;

  return (
    <div className="voucher-group-view">
      <div className="voucher-group-view-heading">
        <div>
          <div className="icon-box">
            <img src={requestUserIcon} />
            <div>{request_user}</div>
          </div>
        </div>
        <div>
          <div className="icon-box">
            <img src={numberIcon} />
            <div>
              <span
                style={{ fontStyle: "italic", fontSize: "80%", color: "grey" }}
              >
                {paid ? "" : "G"}
                {amount}
              </span>{" "}
              {last_number}-{first_number}
            </div>
          </div>
        </div>
        <div className="flex" style={{ opacity: 0.75 }}>
          <div className="icon-box">
            <img src={printIcon} />
            {prettyDate(print_date, "", true, { month: "short" })}
          </div>
          {received_date ? (
            <div className="icon-box">
              <img src={receiveIcon} />
              {prettyDate(received_date, "", true, { month: "short" })}
            </div>
          ) : (
            <div>-</div>
          )}
        </div>
        {setOptions ? (
          <div className="voucher-group-options-wrap">
            <button
              className="normal-button"
              onClick={() => {
                setOptions(group?.id);
              }}
            >
              <img
                src={optionsIcon}
                style={{ width: "15px", marginRight: 0 }}
              />
            </button>
            <div
              className="voucher-group-options"
              style={{ display: options === group?.id ? "block" : "none" }}
            >
              <VoucherGroupOptionsList items={items} setUpdate={setUpdate} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="view-items">
        {items?.map((v: any) => (
          <VoucherListGroupSingle
            v={v}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
          />
        ))}
      </div>
    </div>
  );
}
