import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";

export function row(row?: any): any[] {
  // indeks/przychod/rozchod/Data
  return [
    {
      name: "Id",
      value: row?.id,
      style: { width: "50px" },
    },
    {
      name: "Data",
      value: prettyDate(row?.Data),
      style: { width: "90px" },
    },
    {
      name: "Indeks",
      value: row?.Indeks,
      style: { width: "90px" },
    },
    {
      name: "Nazwa",
      value: (
        <>
          {row?.pNazwa}{" "}
          {row?.NrSerii ? (
            <span
              style={{
                color: "brown",
                fontSize: "90%",
                fontStyle: "italic",
              }}
            >
              {row?.NrSerii}
            </span>
          ) : (
            <></>
          )}
        </>
      ),
    },

    {
      name: "Przychód",
      value:
        row?.Przychod != 0 ? (
          prettyNumber(row?.Przychod, {
            round: 2,
            postfix: row?.jm?.replace("kg.", "kg")?.replace("l.", "l"),
          })
        ) : (
          <>-</>
        ),
    },
    {
      name: "Rozchód",
      value:
        row?.Rozchod != 0 ? (
          prettyNumber(row?.Rozchod, {
            round: 2,
            postfix: row?.jm?.replace("kg.", "kg")?.replace("l.", "l"),
          })
        ) : (
          <>-</>
        ),
    },
    {
      name: "Cena netto/jedn.",
      value: prettyNumber(row?.PriceN, { round: 2, postfix: "zł" }),
    },
    {
      name: "Cena netto/łącznie.",
      value: prettyNumber(row?.Stan * row?.PriceN, { round: 2, postfix: "zł" }),
    },
  ];
}
