import { formFull } from "../forms_new/formContentType";
import { scheduleCurrent, yesOrNo } from "./definitions/common";
import civilWeddingIcon from "../../images/icons/wedding/civil-wedding.svg";
import danceIcon from "../../images/icons/wedding/dance.svg";
import musicIcon from "../../images/icons/new/music.svg";
import entranceIcon from "../../images/icons/wedding/entrance.svg";
import champagneIcon from "../../images/icons/wedding/champagne.svg";
import breadIcon from "../../images/icons/wedding/bread.png";
import domeIcon from "../../images/icons/wedding/dish_cover.png";
import tableIcon from "../../images/icons/wedding/tables.png";
import napkinIcon from "../../images/icons/wedding/napkins.png";
import smokeIcon from "../../images/icons/wedding/smoke.png";
import flowerIcon from "../../images/icons/wedding/flowers.svg";
import cardsIcon from "../../images/icons/wedding/name_card.png";
import giftsIcon from "../../images/icons/wedding/gift.svg";
import signboardIcon from "../../images/icons/wedding/signboard.png";
import guestbookIcon from "../../images/icons/wedding/guestbook.png";
import photoBoothIcon from "../../images/icons/wedding/camera.png";
import candleIcon from "../../images/icons/wedding/candle.png";
import chairIcon from "../../images/icons/wedding/chair2.png";
import tableClothIcon from "../../images/icons/wedding/tablecloth.png";
import plateIcon from "../../images/icons/wedding/plate.png";
import cakeIcon from "../../images/icons/wedding/cake.svg";
import fruitIcon from "../../images/icons/wedding/fruit.png";
import dessertIcon from "../../images/icons/wedding/mini-dessert.png";
import fizzyIcon from "../../images/icons/wedding/fizzy.png";
import giftBoxesIcon from "../../images/icons/wedding/gift-box.png";
import countryTable from "../../images/icons/wedding/farmhouse.png";
import illuminationIcon from "../../images/icons/wedding/illumination.png";
import loveIcon from "../../images/icons/wedding/love.png";
import alcoholIcon from "../../images/icons/wedding/alcohol.png";
import barrelIcon from "../../images/icons/wedding/barrel.png";
import drinkBarIcon from "../../images/icons/wedding/drinkbar.png";
import kidstartersIcon from "../../images/icons/wedding/tapas.png";
import kidsTableIcon from "../../images/icons/wedding/kids.png";
import carIcon from "../../images/icons/wedding/car.png";
import buffetIcon from "../../images/icons/wedding/buffet.png";
import wallIcon from "../../images/icons/wedding/wall.png";
import carpetIcon from "../../images/icons/wedding/carpet.png";
import notesIconSrc from "../../images/icons/wedding/arrow.png";

const notesIcon = {
    icon: notesIconSrc,
    iconStyle: {
        width:"15px"
    }
}

const flex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const ledOptions = [
  { id: 1, name: "biały", colorFrame: "white" },
  { id: 2, name: "niebieski", colorFrame: "#358dff" },
  { id: 3, name: "zielony", colorFrame: "#3bc754" },
  { id: 4, name: "różowy", colorFrame: "#f972bc" },
  { id: 5, name: "czerwony", colorFrame: "red" },
  { id: 6, name: "pomarańczowy", colorFrame: "orange" },
  { id: 7, name: "żółty", colorFrame: "yellow" },
  { id: 8, name: "fioletowy", colorFrame: "#8720f5" },
];

export const weddingForm = (data: any): formFull => ({
  name: "Wesele",
  visibleIf: (d: any) => d.event_type == 4,
  tabStyle: {
    fontWeight:"bold",
    boxShadow:"0 -5px 0 rgb(159, 135, 62) inset"
  },
  className: "weddingLayout area-layout",
  // hide: data
  content: [
    [
      // WEDDING
      {
        name: "wedding&civil_ceremony",
        description: "Ślub cywilny",
        icon: civilWeddingIcon,
      },
      {
        name: "wedding&civil_ceremony_at_agit",
        label: "Czy odbywa się u nas?",
        outerStyle: flex,
        initialValue: data ? data?.["wedding&civil_ceremony_at_agit"] : "",
        element: "select-picker",
        optionsList: yesOrNo,
        // required: "Czy ślub cywilny odbywa się u nas?",
      },
      {
        name: "wedding&civil_ceremony_location",
        label: "Miejsce ślubu",
        outerStyle: flex,
        initialValue: data ? data?.["wedding&civil_ceremony_location"] : "",
        visibleIf: (d: any) => d?.["wedding&civil_ceremony_at_agit"] == 2,
        element: "select-picker",
        optionsList: [
          { id: 1, name: "Kryształowa" },
          { id: 2, name: "Schody" },
        ],
        // validate: (v: any, all: any) =>
        //   all?.["wedding&civil_ceremony_at_agit"] == 2
        //     ? (v ? false : "W którym miejscu odbywa się ślub cywilny?") : false
      },
      {
        name: "wedding&civil_ceremony_comment",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d?.["wedding&civil_ceremony_at_agit"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
      },
    ],
    [
      {
        name: "wedding&entrance",
        label: "Wejście",
        initialValue: data ? data?.["wedding&entrance"] : "",
        icon: entranceIcon,
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "tył" },
          { id: 2, name: "przód" },
        ],
      },
      {
        name: "wedding&entrance_comment",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d?.["wedding&entrance"],
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&welcome_champagne",
        label: "szampan na powitanie",
        icon: champagneIcon,
        element: "select-picker",

        optionsList: [
          { id: 1, name: "nie" },
          { id: 2, name: "tak" },
          { id: 3, name: "tak / bezalk. dla panny młodej" },
          { id: 4, name: "tak / bezalk. dla pana młodego" },
        ],
      },
      {
        name: "wedding&welcome_champagne_comment",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d?.["wedding&welcome_champagne"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&welcome_bread",
        label: "Chlebek na powitanie",
        icon: breadIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&welcome_bread_from",
        label: "Od kogo",
        visibleIf: (d: any) => d?.["wedding&welcome_bread"] == 2,
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od pary młodej" },
        ],
      },
      {
        name: "wedding&domes",
        label: "Kopułki na danie główne",
        icon: domeIcon,
        iconStyle: { opacity: 1 },
        element: "select-picker",
        outerStyle: flex,
        optionsList: yesOrNo,
        // Kopułki na danie główne TAK/ NIE  ile ? 1, 2,3, lub 4
      },
      {
        name: "wedding&dome_amount",
        label: "Ile?",
        visibleIf: (d: any) => d?.["wedding&domes"] == 2,
        element: "select-picker",
        outerStyle: { ...flex, marginTop: "-3.5px", zIndex: 0 },
        optionsList: [
          { id: 1, name: "1" },
          { id: 2, name: "2" },
          { id: 3, name: "3" },
          { id: 4, name: "4" },
        ],
        // Kopułki na danie główne TAK/ NIE  ile ? 1, 2,3, lub 4
      },
      {
        name: "wedding&table_cards",
        label: "Winietki",
        icon: cardsIcon,
        iconStyle: { opacity: 1 },
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&little_gifts",
        label: "Prezenciki dla gości",
        icon: giftsIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&little_gift_comments",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d["wedding&little_gifts"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&tableau",
        label: "Tablo",
        icon: signboardIcon,
        iconStyle: { opacity: 1 },
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&tableau_comments",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d["wedding&tableau"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&guest_book",
        label: "Księga gości",
        icon: guestbookIcon,
        iconStyle: { opacity: 1 },
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&guest_book_comments",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d["wedding&guest_book"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&dance_venue",
        label: "Sala taneczna",
        icon: danceIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: [
          { id: 1, name: "Kryształowa" },
          { id: 2, name: "Perłowa" },
        ],
      },
      {
        name: "wedding&music",
        label: "Muzyka",
        icon: musicIcon,
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "DJ" },
          { id: 2, name: "zespół" },
        ],
      },
      {
        name: "wedding&photo_booth",
        label: "Fotobudka",
        icon: photoBoothIcon,
        iconStyle: { opacity: 1 },
        outerStyle: flex,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&photo_booth_time_start",
        label: "Fotobudka - od godz.",
        element: "input",
        visibleIf: (d: any) => d["wedding&photo_booth"] == 2,
        inputType: "time",
        format: "half-1",
      },
      {
        name: "wedding&photo_booth_time_end",
        label: "Fotobudka - do godz.",
        element: "input",
        visibleIf: (d: any) => d["wedding&photo_booth"] == 2,
        inputType: "time",
        format: "half-2",
      },
      {
        name: "wedding&photo_booth_location",
        visibleIf: (d: any) => d["wedding&photo_booth"] == 2,
        label: "Fotobudka - miejsce ustawienia",
        element: "input",
      },
    ],
    [
      {
        name: "wedding&table_type",
        label: "Stoły",
        icon: tableIcon,
        iconStyle: { opacity: 1 },
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "okrągłe" },
          { id: 2, name: "prostokątne" },
        ],
      },
      {
        name: "wedding&table_type_comment",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d?.["wedding&table_type"],
        element: "textarea",
        className: "linkedToPrevious",
        style: { height: "28px" },
      },
      {
        name: "wedding&table_linen",
        label: "Obrusy",
        element: "select-picker",
        icon: tableClothIcon,
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "białe", colorFrame:"white" },
          { id: 2, name: "czarne", colorFrame:"black" },
          { id: 3, name: "inne" },
        ],
      },
      {
        name: "wedding&table_linen_comments",
        visibleIf: (d: any) => d?.["wedding&table_linen"] == 3,
        label: "Jakie?",
        element: "textarea",
        style:{
            height:"32px"
        },
        className: "linkedToPrevious",
      },
      {
        name: "wedding&chairs",
        label: "Krzesła",
        element: "select-picker",
        icon: chairIcon,
        iconStyle: { opacity: 1 },
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "glamour" },
          { id: 2, name: "loft" },
        ],
      },
      {
        name: "wedding&chair_covers",
        label: "Pokrowce na krzesła",
        visibleIf: (d: any) => d?.["wedding&chairs"] == "1",
        element: "select-picker",
        outerStyle: flex,
        optionsList: yesOrNo,
      },
      {
        name: "wedding&napkins",
        label: "Serwetki",
        outerStyle: flex,
        icon: napkinIcon,
        iconStyle: { opacity: 1 },
        element: "select-picker",
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od pary młodej" },
        ],
      },
      {
        name: "wedding&napkin_type",
        label: "Ułożenie serwetek",
        element: "select-picker",
        outerStyle: { ...flex, marginTop: "-3px" },
        optionsList: [
          { id: 1, name: "na płasko" },
          { id: 2, name: "na stojąco" },
        ],
      },
      {
        name: "wedding&subplates",
        label: "Podtalerze",
        element: "select-picker",
        icon: plateIcon,
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od florystki" },
        ],
      },
      {
        name: "wedding&candles",
        label: "Świeczniki",
        icon: candleIcon,
        // iconStyle: {width:"18px"},
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od florystki" },
        ],
      },
      {
        name: "wedding&flowers",
        label: "Kwiaty",
        icon: flowerIcon,
        element: "select-picker",
        outerStyle: flex,
        style: {
          justifyContent: "flex-end",
        },
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od florystki" },
          { id: 3, name: "od pary młodej" },
        ],
      },
      {
        name: "wedding&candles_comments",
        // label: "Uwagi",
        ...notesIcon,
        style: { height: "28px" },
        visibleIf: (d: any) => d["wedding&candles"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
      },
      {
        name: "wedding&lit_buffet",
        label: "Podświetlany bufet",
        icon: buffetIcon,
        element: "select-picker",
        outerStyle: flex,
        optionsList: yesOrNo,
      },
      {
        name: "wedding&wall",
        label: "Ścianka",
        icon: wallIcon,
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od florystki" },
        ],
      },
      {
        name: "wedding&red_carpet",
        label: "Czerwony dywan",
        icon: carpetIcon,
        element: "select-picker",
        outerStyle: flex,
        optionsList: [
          { id: 1, name: "od nas" },
          { id: 2, name: "od florystki" },
        ],
      },
      {
        name: "wedding&red_carpet_comments",
        ...notesIcon,
        element: "textarea",
        className: "linkedToPrevious",
        visibleIf: (d: any) => d?.["wedding&red_carpet"],
        style: { height: "28px" },
      },
      {
        name: "wedding&heavy_smoke",
        label: "Ciężki dym",
        icon: smokeIcon,
        iconStyle: { opacity: 1 },
        element: "select-picker",
        // required: true,
        outerStyle: flex,
        optionsList: yesOrNo,
      },
      {
        name: "wedding&heavy_smoke_comments",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d: any) => d?.["wedding&heavy_smoke"] == 2,
        style: { height: "28px" },
        className: "linkedToPrevious",
        element: "textarea",
      },
      {
        name: "wedding&heavy_smoke_info",
        visibleIf: (d: any) => d["wedding&heavy_smoke"] == 2,
        description: <div style={{ color: "red" }}>Wyłączyć czujki!!!</div>,
      },
    ],

    [
      {
        name: "wedding&cake",
        label: "Ciasto",
        icon: cakeIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od gości",
          },
        ],
      },
      {
        name: "wedding&cake_comments",
        // label: "Uwagi",
        ...notesIcon,
        className: "linkedToPrevious",
        visibleIf: (d: any) => d["wedding&cake"] == 2,
        element: "textarea",
        style: { height: "28px" },
      },
      {
        name: "wedding&fruit",
        icon: fruitIcon,
        label: "Owoce",
        outerStyle: flex,
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od gości",
          },
        ],
      },

      {
        name: "wedding&mini_desserts",
        label: "Mini deserki",
        icon: dessertIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od gości",
          },
        ],
      },
      {
        name: "wedding&mini_desserts_comments",
        // label: "Uwagi",
        ...notesIcon,
        className: "linkedToPrevious",
        visibleIf: (d: any) => d["wedding&mini_desserts"] == 2,
        element: "textarea",
        style: { height: "28px" },
      },
      {
        name: "wedding&fizzy_drinks",
        label: "Napoje gazowane",
        icon: fizzyIcon,
        outerStyle: flex,
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "bufet",
          },
          {
            id: 2,
            name: "stoły",
          },
        ],
      },

      /// ADD TO DB
      {
        name: "wedding&gift_box",
        label: "Gościńce",
        outerStyle:flex,
        icon: giftBoxesIcon,
        iconStyle: {
            width:"16px"
        },
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od gości",
          },
        ],
      },
      {
        name: "wedding&gift_box_comments",
        // label: "Uwagi",
        ...notesIcon,
        visibleIf: (d:any) => d?.["wedding&gift_box"],
        className: "linkedToPrevious",
        element: "textarea",
        style: { height: "28px" },
      },
      {
        name: "wedding&country_style_buffet",
        label: "Stół wiejski",
        icon: countryTable,
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "brak",
          },
          {
            id: 2,
            name: "od nas",
          },
          {
            id: 3,
            name: "od gości",
          },
        ],
        format: "three-quarters-1"
      },
      {
        name: "wedding&country_style_buffet_servings",
        label: "Porcje",
        visibleIf: (d: any) => d?.["wedding&country_style_buffet"] == 2,
        inputType: "number",
        initialValue: 0,
        min: 0,
        max: 100,
        format: "quarter-4"
        // required: "Podaj ilość porcji",
      },
      {
        name: "wedding&country_style_buffet_type",
        visibleIf: (d: any) => Number(d?.["wedding&country_style_buffet"]) > 1,
        label: "Rodzaj stołu wiejskiego",
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "wiejska chata",
          },
          {
            id: 2,
            name: "okrągły",
          },
          {
            id: 3,
            name: "prostokątny",
          },
        ],
      },
    ],

    [
      {
        name: "wedding&led_mezzanine",
        label: "LED sala antresolowa",
        icon: illuminationIcon,
        element: "select-picker",
        optionsList: ledOptions,
        visibleIf: (values: any) => {
          const schedules = scheduleCurrent(values);
          const venues = [
            ...new Set(schedules.flat().map((s: any) => s.venue)),
          ];
          const findVenue = venues?.find((v: any) =>
            [2, 8, 13, 16].includes(Number(v))
          );
          return findVenue !== undefined ? true : false;
        },
      },
      {
        name: "wedding&led_crystal",
        label: "LED sala kryształowa",
        icon: illuminationIcon,
        element: "select-picker",
        optionsList: ledOptions,
        visibleIf: (values: any) => {
          const schedules = scheduleCurrent(values);
          const venues = [
            ...new Set(schedules.flat().map((s: any) => s.venue)),
          ];
          const findVenue = venues?.find((v: any) =>
            [1, 6, 7, 8].includes(Number(v))
          );
          return findVenue !== undefined ? true : false;
        },
      },
      {
        name: "wedding&led_pearl",
        label: "LED sala perłowa",
        icon: illuminationIcon,
        element: "select-picker",
        optionsList: ledOptions,
        visibleIf: (values: any) => {
          const schedules = scheduleCurrent(values);
          const venues = [
            ...new Set(schedules.flat().map((s: any) => s.venue)),
          ];
          const findVenue = venues?.find((v: any) =>
            [3, 13].includes(Number(v))
          );
          return findVenue !== undefined ? true : false;
        },
      },
      {
        name: "wedding&love",
        label: "Napis LOVE",
        icon: loveIcon,
        element: "select-picker",
        optionsList: yesOrNo,
        outerStyle: {...flex, marginTop:"24px"},
        format:"half-1"
      },
      {
        name: "wedding&love_color",
        label: "LOVE - kolor podświetlenia",
        icon: loveIcon,
        visibleIf: (d: any) => d?.["wedding&love"] == 2,
        element: "select-picker",
        optionsList: [
          { id: 1, name: "biały", colorFrame: "white" },
          { id: 2, name: "niebieski", colorFrame: "#358dff" },
          { id: 3, name: "zielony", colorFrame: "#3bc754" },
          { id: 4, name: "różowy", colorFrame: "#f972bc" },
          { id: 5, name: "czerwony", colorFrame: "red" },
          { id: 6, name: "pomarańczowy", colorFrame: "orange" },
          { id: 7, name: "żółty", colorFrame: "yellow" },
          { id: 8, name: "fioletowy", colorFrame: "#8720f5" },
        ],
      },
      {
        name: "wedding&love_comments",
        // label: "Uwagi",
        ...notesIcon,
        // icon: loveIcon,
        visibleIf: (d: any) => d?.["wedding&love"] == 2,
        element: "textarea",
        className: "linkedToPrevious",
      },
    ],
    [
      {
        name: "wedding&kids_table",
        label: "Stół dla dzieci",
        outerStyle: flex,
        icon: kidsTableIcon,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&kids_starters",
        label: "Przystawki dziecięce",
        outerStyle: flex,
        icon: kidstartersIcon,
        element: "select-picker",
        optionsList: yesOrNo,
      },
      {
        name: "wedding&kids_starters_comments",
        // label: "uwagi",
        ...notesIcon,
        visibleIf: (d:any) => d?.["wedding&kids_starters"] == 2,
        className: "linkedToPrevious",
        element: "textarea",
      },
      {
        name: "wedding&car_park_spots",
        label: "Liczba zablok. miejsc parkingowych",
        icon: carIcon,
        inputType: "number",
        outerStyle: {
            marginTop:"24px"
        },
        min: 0,
        max: 10,
      },
      {
        name: "wedding&car_park_newlyweds",
        label: "Blokada miejsca dla pary młodej",
        icon: carIcon,
        element: "select-picker",
        optionsList: yesOrNo,
      },
    ],
    [
      {
        name: "wedding&alcohol",
        label: "Alkohol",
        icon: alcoholIcon,
        element: "select-picker",
        outerStyle:flex,
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od pary młodej",
          },
        ],
      },
      {
        name: "wedding&barrels",
        label: "Beczki na bimber",
        icon: barrelIcon,
        outerStyle:flex,
        iconStyle:{
            width:"16px"
        },
        element: "select-picker",
        optionsList: [
          {
            id: 1,
            name: "od nas",
          },
          {
            id: 2,
            name: "od gości",
          },
        ],
      },
      {
        name: "wedding&drink_bar",
        label: "Drinkbar",
        iconStyle:{
            width:"16px"
        },
        icon: drinkBarIcon,
        element: "select-picker",
        outerStyle:flex,
        optionsList: [
          {
            id: 1,
            name: "brak",
          },
          {
            id: 2,
            name: "od nas",
          },
          {
            id: 3,
            name: "firma zewnętrzna",
          },
        ],
      },
    ],
    [
      {
        name: "wedding&alcohol_type_agit",
        label: "Alkohol od nas",
        visibleIf: (d: any) => d?.["wedding&alcohol"] == 1,
        element: "select-picker",
        pickerMulti: true,
        outerStyle:flex,
        optionsList: [
          {
            id: 1,
            name: "wino",
          },
          { id: 2, name: "wódka" },
        ],
      },
      {
        name: "wedding&alcohol_type_client",
        label: "Alkohol od pary młodej",
        visibleIf: (d: any) => d?.["wedding&alcohol"] == 2,
        element: "select-picker",
        pickerMulti: true,
        outerStyle:flex,
        optionsList: [
          {
            id: 1,
            name: "wódka",
          },
          {
            id: 2,
            name: "wino",
          },
          {
            id: 3,
            name: "piwo",
          },
          {
            id: 4,
            name: "bimber",
          },
          {
            id: 5,
            name: "nalewki",
          },
          {
            id: 6,
            name: "whisky",
          },
        ],
      },
      {
        name: "wedding&alcohol_type_custom",
        label: "Uwagi - alkohole",
        ...notesIcon,
        element: "textarea",
        
      },
    ],
  ],
});

// console.log(weddingForm([]));
