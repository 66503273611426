import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import errIcon from "../images/icons/data-record.svg";
import calIcon from "../images/icons/main_icon2.png";
import aiIcon from "../images/icons/ai.svg";
import procIcon from "../images/icons/main_icon3.png";
import taskIcon from "../images/icons/main_icon4.png";
import chatIcon from "../images/icons/main_icon5.png";
import restaurantIcon from "../images/icons/restaurant.svg";
import arrivalIcon from "../images/icons/arrival.svg";
import regIcon from "../images/icons/new-user.svg";
import vouchersIcon from "../images/icons/vouchers.png";
import usersIcon from "../images/icons/people.svg";
import employeesIcon from "../images/icons/employees.png";
import roomsIcon from "../images/icons/floorplan.png";
import experimentIcon from "../images/icons/lab.png";
import attendanceIcon from "../images/icons/main_icon8.svg";
import { ViewContext } from "./contexts/ViewContext";
import invoicesIcon from "../images/icons/invoices.png";
import { objectOrJSONparse } from "./functions/tools";

export default function Main({ micro }: { micro?: Boolean }) {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("main");
  const {
    logOut,
    role,
    userID,
    auth: authAll,
    permissions: p,
    userType,
  } = useContext(UserContext);

  const auth = objectOrJSONparse(authAll?.["modules"]);
  const authReg = objectOrJSONparse(authAll?.["registers"]);

  return (
    <div className={micro ? "" : "appWrap"}>
      <div className={micro ? "micro-main" : "appContent"}>
        <div className={micro ? "micro-buttons" : "mainButtons"}>
          <nav>
            <ul>
              <>
                {auth?.["attendance"] > 0 ? (
                  <li>
                    <Link to="attendance" unstable_viewTransition>
                      <img src={attendanceIcon} />
                      <div>Czas pracy</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["schedules"] > 0 ? (
                  <li>
                    <Link to="schedules" unstable_viewTransition>
                      <img src={attendanceIcon} />
                      <div>Grafiki</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["registers"] > 0 ? (
                  <li>
                    <Link to="registers\" unstable_viewTransition>
                      <img src={errIcon} />
                      <div>Rejestry</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["calendar"] > 0 ? (
                  <li>
                    <Link to="calendar" unstable_viewTransition>
                      <img src={calIcon} />
                      <div>Kalendarz wydarzeń</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["procedures"] > 0 ? (
                  <li>
                    <Link to="procedures" unstable_viewTransition>
                      <img src={procIcon} />
                      <div>Procedury</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["tasks"] > 0 ? (
                  <li>
                    <Link to="tasks" unstable_viewTransition>
                      <img src={taskIcon} />
                      <div>Zadania</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {/* KADRY: mw, kj, mż, ik */}
                {userID == 1 /* MW */ ||
                userID == 7 /* KJ */ ||
                userID == 18 ||
                userID == 12 /* MŻ */ ||
                auth?.["employee_files"] > 0 ? (
                  <li>
                    <Link to="../employee-files" unstable_viewTransition>
                      <img src={employeesIcon} />
                      <div>Kadry</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {/*
                {["dev", "sales", "admin", "organisation"]?.includes(
                  userType as string
                ) ? (
                  <li>
                    <Link to="../invoices-view"  unstable_viewTransition>
                      <img src={invoicesIcon} />
                      <div>Faktury sprzedażowe</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              */}
                {/* {["dev", "sales", "admin"]?.includes(userType as string) ? ( */}
                {auth?.["rooms_view"] > 0 ? (
                  <li>
                    <Link to="../rooms-view" unstable_viewTransition>
                      <img src={roomsIcon} />
                      <div>Status pokoi</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["chat"] > 0 ? (
                  <li>
                    <Link to="chat" unstable_viewTransition>
                      <img src={chatIcon} />
                      <div>Czaty</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}

                {auth?.["register_user"] > 0 ? (
                  <>
                    <li>
                      <Link to="register-user" unstable_viewTransition>
                        <img src={regIcon} />
                        <div>Nowy użytkownik</div>
                      </Link>
                    </li>
                  </>
                ) : (
                  <></>
                )}
                {/* {auth?.["users"] > 0? (
                  <li>
                    <Link to="users" unstable_viewTransition>
                      <img src={usersIcon} />
                      <div>Użytkownicy</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )} */}
                {auth?.["devs"] > 0 ? (
                  <li>
                    <Link to="devs">
                      <img style={{ width: "70%" }} src={experimentIcon} />
                      <div>Nowe funkcje</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {authReg?.["lsi-sales-invoices"] >= 1 ? (
                  <li>
                    <Link to="invoices-view" unstable_viewTransition>
                      <img src={invoicesIcon} />
                      <div>Faktury sprzedażowe</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {authReg?.["vouchers"] >= 1 ? (
                  <li>
                    <Link to="vouchers" unstable_viewTransition>
                      <img src={vouchersIcon} />
                      <div>Vouchery</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {authReg?.["lsi-arrivals"] >= 1 ? (
                  <li>
                    <Link to="registers/lsi-arrivals" unstable_viewTransition>
                      <img src={arrivalIcon} />
                      <div>Przyjazdy</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["register_user"] > 0 ? (
                  <li>
                    <Link to="menu-editor" unstable_viewTransition>
                      <img src={restaurantIcon} />
                      <div>Karta dań</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {auth?.["register_user"] > 0 ? (
                  <li>
                    <Link to="ask-gpt" unstable_viewTransition>
                      <img src={aiIcon} />
                      <div>Funkcje AI</div>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
              </>
            </ul>
          </nav>
        </div>
        {/*}  <button className="button logOut" onClick={() => logOut()}>
          Wyloguj
  </button>*/}
      </div>
    </div>
  );
}
