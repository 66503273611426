export default function genericCaseMap(name: string) {
  let fetchAddress = "";
  let fetchAddressFull = "";
  switch (name) {
    case "errorTable":
      {
        fetchAddress = "get-error-table.php";
      }
      break;
    case "getFiles":
      {
        fetchAddress = "get-files.php";
      }
      break;
    case "showError":
      {
        fetchAddress = "error-table/get-single-error.php";
      }
      break;
    case "problemTypes":
      {
        fetchAddress = "error-table/get-problem-types.php";
      }
      break;
    case "salesDocumentTypes":
      {
        fetchAddress = "error-table/get-sales-document-types.php";
      }
      break;
    case "reportingFormTypes":
      {
        fetchAddress = "error-table/get-reporting-form-types.php";
      }
      break;
    case "whatIsCorrected":
      {
        fetchAddress = "error-table/get-what-is-corrected.php";
      }
      break;
    case "correctionSigned":
      {
        fetchAddress = "error-table/get-correction-signed.php";
      }
      break;
    case "employees":
      {
        fetchAddress = "error-table/get-employees.php";
      }
      break;
    case "employeesGeneric":
      {
        fetchAddress = "error-table/get-employees-generic.php";
      }
      break;
    case "employeesHybridField":
      {
        fetchAddress = "error-table/get-employees-search.php";
      }
      break;
    case "employeesAdvanced":
      {
        fetchAddress = "error-table/get-employees-advanced.php";
      }
      break;
    case "employeeGroups":
      {
        fetchAddress = "error-table/get-employee-groups.php";
      }
      break;
    case "paymentFormsList":
      {
        fetchAddress = "get-payment-forms-list.php";
      }
      break;
    case "paymentFormChange":
      {
        fetchAddress = "get-payment-form-change.php";
      }
      break;
    case "users":
      {
        fetchAddress = "get-users.php";
      }
      break;
    case "discussion":
      {
        fetchAddress = "get-discussion.php";
      }
      break;
    case "discussionCategories":
      {
        fetchAddress = "get-discussion-categories.php";
      }
      break;
    case "onlineUsers":
      {
        fetchAddress = "get-online-users.php";
      }
      break;
    case "userList":
      {
        fetchAddress = "get-all-users.php";
      }
      break;
    case "auth":
      {
        fetchAddress = "get-auth.php";
      }
      break;
    case "sessions":
      {
        fetchAddress = "get-sessions.php";
      }
      break;
    case "transferReceipts":
      {
        fetchAddress = "error-table/get-transfer-receipts.php";
      }
      break;
    case "events":
      {
        fetchAddress = "error-table/get-events.php";
      }
      break;
    case "event-calendar":
      {
        fetchAddress = "get-event-calendar.php";
      }
      break;
    case "event-calendar-potential":
      {
        fetchAddress = "get-potential-events.php";
      }
      break;
    case "event-calendar-simple":
      {
        fetchAddress = "get-event-calendar-simple.php";
      }
      break;
    case "search-event-calendar":
      {
        fetchAddress = "search-event-calendar.php";
      }
      break;
    case "event-calendar-basic":
      {
        fetchAddress = "get-event-calendar-tv.php";
      }
      break;
    case "event-calendar-archive":
      {
        fetchAddress = "get-event-calendar.php";
      }
      break;
    case "event-calendar-history":
      {
        fetchAddress = "get-calendar-history.php";
      }
      break;
    case "venues":
      {
        fetchAddress = "get-venues.php";
      }
      break;
    case "attendance":
    case "attendance_3maja":
      {
        fetchAddress = "get-attendance.php";
      }
      break;
    case "cards":
      {
        fetchAddress = "get-cards.php";
      }
      break;
    case "attendancePairs":
      fetchAddress = "get-attendance-pairs.php";
      break;
    case "attendanceDetails":
      fetchAddress = "get-attendance-details.php";
      break;
    case "attendanceLatest":
      fetchAddress = "get-attendance-latest.php";
      break;
    case "holidayRanges":
      fetchAddress = "get-holiday-ranges.php";
      break;
    case "holidays":
      fetchAddress = "get-holidays.php";
      break;
    case "holidaysSuggested":
      fetchAddress = "get-holidays-suggested.php";
      break;
    case "holidayCount":
      fetchAddress = "get-holiday-count.php";
      break;
    case "holidayCountAtDate":
      fetchAddress = "get-holiday-count-at-date.php";
      break;
    case "holidayCountView":
      fetchAddress = "get-holiday-count-view.php";
      break;
    case "holidayTypes":
      fetchAddress = "get-holiday-types.php";
      break;
    case "holidayRequests":
      fetchAddress = "get-holiday-requests.php";
      break;
    case "holidayReport":
      fetchAddress = "get-holiday-report.php";
      break;
    case "holidayReportByPerson":
      fetchAddress = "get-holiday-report-by-person.php";
      break;
    case "annualAttendanceReport":
      fetchAddress = "get-annual-sums.php";
      break;
    case "getUserByCode":
      fetchAddress = "get-user-by-code.php";
      break;
    case "attendanceSums":
      fetchAddress = "get-attendance-sums.php";
      break;
    case "attendanceAnnualSum":
      fetchAddress = "get-attendance-annual-sum.php";
      break;
    case "holidaySums":
      fetchAddress = "get-holiday-sums.php";
      break;
    case "schedules":
      fetchAddress = "schedules/get-schedules.php";
      break;
    case "scheduleData":
      fetchAddress = "schedules/get-schedule-data.php";
      break;
    case "scheduleTemplates":
      fetchAddress = "schedules/get-templates-specials.php";
      break;
    case "nip":
      fetchAddress = "gus-api/queryM.php";
      break;
    case "lsi_invoice_query":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_invoices.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_invoices.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_invoices.php";
      break;
    case "lsi_receipt_query":
      fetchAddressFull =
        //"https://data.agit.com.pl/hotelhub/backend/lsi/query_receipts.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_receipts.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_receipts.php";
      break;
    case "lsi_guests_by_invoice":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_guests_by_invoice.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_guests_by_invoice.php";
      break;
    case "lsi_restaurant":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_restaurant.php";
      break;
    case "lsi_room_definitions":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_room_definitions.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_room_definitions.php";
      break;
    case "lsi_rooms":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_rooms.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_rooms.php";
      break;
    case "lsi_sessions":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_sessions.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_sessions.php";
      break;
    case "lsi_profitroom":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_profitroom.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_profitroom.php";
      break;
    case "lsi_bookings":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_bookings.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_bookings.php";
      break;
    case "lsi_bookings_today":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_restaurant.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_bookings_today.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_bookings_today.php";
      break;
    case "lsi_vouchers":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_vouchers.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_vouchers.php";
      break;
    case "lsi_voucher_changes":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_voucher_changes.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_voucher_changes.php";
      break;
    case "lsi_clients":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_clients.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_clients.php";
      break;
    case "lsi_locale":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_locale.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_locale.php";
      break;
    case "lsi_deliveries":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_deliveries.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_deliveries.php";
      break;
    case "lsi_stats":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_stats.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_stats.php";
      break;
    case "lsi_incomings":
      fetchAddressFull =
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_incomings.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_incomings.php";
      break;
    case "p24":
      fetchAddressFull = "https://happ.agit.com.pl/backend/p24/create-link.php";
      break;
    case "calendar_auth":
      fetchAddress = "get-calendar-auth.php";
      break;
    case "get_version":
      fetchAddress = "get-version.php";
      break;
    case "event_calendar_payments":
      fetchAddress = "get-event-payments.php";
      break;
    case "employee_medicals":
      fetchAddress = "get-medicals.php";
      break;
    case "employee_bhp":
      fetchAddress = "get-bhp.php";
      break;
    case "employee_sanitary":
      fetchAddress = "get-sanitary.php";
      break;
    case "employee_contracts":
      fetchAddress = "get-employee-contracts.php";
      break;
    case "uploads_list":
      fetchAddress = "get-uploads-list.php";
      break;
    case "contract_types":
      fetchAddress = "get-contract-types.php";
      break;
    case "employee_dates_incoming":
      fetchAddress = "get-incoming-employee-dates.php";
      break;
    case "employee_dates_incoming_sorted":
      fetchAddress = "get-incoming-employee-dates-sorted.php";
      break;
    case "vouchers":
      {
        fetchAddress = "get-vouchers.php";
      }
      break;
    case "purchase_invoices":
      {
        fetchAddress = "external/get-purchase-invoices.php";
      }
      break;
    case "get-log":
      {
        fetchAddress = "get-log.php";
      }
      break;
    case "invoice-mail-preview":
      {
        fetchAddress = "mail/invoice-mail-preview.php";
      }
      break;
    case "voucher-register-memory":
      {
        fetchAddress = "get-voucher-memory.php";
      }
      break;
    case "voucher-register":
      {
        fetchAddress = "get-voucher-register.php";
      }
      break;
    case "management":
      {
        fetchAddress = "get-management.php";
      }
      break;
    case "calendar-report":
      {
        fetchAddress = "get-event-calendar-report.php";
      }
      break;
    case "lsi_balance_list":
      {
        fetchAddressFull =
          // "https://data.agit.com.pl/hotelhub/backend/lsi/query_invoices.php";
          process.env.NODE_ENV === "production"
            ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_balance_list.php"
            : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_balance_list.php";
      }
      break;
    case "check-names":
      {
        fetchAddress = "check-name.php";
      }
      break;
    case "restaurant-menus":
      {
        fetchAddress = "get-restaurant-menus.php";
      }
      break;
    case "restaurant-menu-single":
      {
        fetchAddress = "get-restaurant-menu-single.php";
      }
      break;
    case "restaurant-menu-categories":
      {
        fetchAddress = "get-restaurant-menu-categories.php";
      }
      break;
    case "get-tv-schedule":
      {
        fetchAddress = "get-tv-schedule.php";
      }
      break;
    case "ask-gpt":
      {
        fetchAddress = "chat-gpt-api/user/ask.php";
      }
      break;
    // case "last-voucher":
    //   {
    //     fetchAddress = "get-last-voucher.php";
    //   }
    //   break;
  }
  return { fetchAddress, fetchAddressFull };
}
