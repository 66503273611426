import { createContext, useContext, useEffect, useState } from "react";
import Tabs from "./Tabs";
import { formFull, forms } from "./formContentType";
import { useForm, FormProvider, useFormState } from "react-hook-form";
import useOptions from "./Elements/useOptions";
import save from "./Actions/save";
import saveLocal from "./Actions/saveLocal";
import { ViewContext } from "../contexts/ViewContext";
import ConfirmChanges from "./ConfirmChanges";
import { processDateSpecificFields } from "../calendar/openEditor";

export const FormDataContext = createContext<{
  schema: forms;
  flatSchema: any;
  options?: Object;
  data?: Object;
  touchedMultiAdds: Object;
  setTouchedMultiAdds: Function;
  customFieldData: Object;
  setCustomFieldData: Function;
  subUpdate: number;
  setSubUpdate: Function;
  subTab?: string;
  setSubTab?: Function;
  isSubmitted: Boolean;
  auth: any;
  tempID?: number;
  setTempID?: Function;
}>({
  schema: {},
  flatSchema: {},
  options: {},
  data: {},
  touchedMultiAdds: {},
  setTouchedMultiAdds: () => {},
  customFieldData: {},
  setCustomFieldData: () => {},
  subUpdate: 0,
  setSubUpdate: () => {},
  subTab: undefined,
  setSubTab: () => {},
  isSubmitted: false,
  auth: {},
  tempID: undefined,
  setTempID: () => {},
});

export default function Form({
  name,
  className,
  auth,
  confirm,
  schema: prepSchema,
  data,
  defaultSubTab,
  submit,
  sideEffects,
  settings,
  history,
  showFunction,
}: {
  name: string;
  schema: forms | Function;
  data: any;
  className?: string;
  auth?: any;
  confirm?: boolean;
  defaultSubTab?: string;
  submit?: Function;
  sideEffects?: Function;
  settings?: string;
  history?: Function; //JSX.Element;
  showFunction?: false | Function;
}) {
  const [tempID, setTempID] = useState(Math.round(Math.random() * 10000000));
  const schema =
    typeof prepSchema === "function"
      ? prepSchema({ ...data, temp_id: tempID })
      : prepSchema;
  const flatSchema = Object.keys(schema)
    .map((key: string) => schema[key].content || schema[key])
    .flat()
    .map((item: any) => item.content || item)
    .flat();

  //console.log(flatSchema);
  // console.log('data-in-form',data)

  const defaults: any = {};

  const { modal2, setModal2 } = useContext(ViewContext);

  flatSchema.forEach((item: any) => {
    defaults[item.name] = item.initialValue
      ? item.initialValue
      : data?.[item.name];
  });

  const methods = useForm({
    mode: "onChange",
    defaultValues: defaults,
  });

  const {
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
    setFocus,
    watch,
    setValue,
    getValues,
  } = methods;

  const [displayedTab, setDisplayedTab] = useState(0);

  const [subUpdate, setSubUpdate] = useState(0);

  const [apiError, setApiError] = useState();

  const [subTab, setSubTab] = useState(() => {
    if (defaultSubTab) {
      return defaultSubTab;
    } else {
      const dates = data && data.dates && JSON.parse(data.dates);
      if (dates) {
        return dates?.[0]?.date;
      }
    }
  });

  const errorKeys = Object.keys(errors);
  const schemaKeys = Object.keys(schema);

  const [customFieldData, setCustomFieldData] = useState(() => {
    const fields: any = {};
    Object.keys(getValues()).forEach((key: string) => {
      fields[key] = {
        tempclass: "",
        dataObject: "",
        updates: 0,
      };
    });
    return fields;
  });

  // console.log(getValues());

  const options: { data: any; loading: Boolean } = useOptions(schema).data;

  const [touchedMultiAdds, setTouchedMultiAdds] = useState({});

  useEffect(() => {
    setCustomFieldData((prev: any) => ({
      ...prev,
      options: {
        ...options,
      },
    }));
  }, [JSON.stringify(options)]);

  /*
  useEffect(() => {
    if (Object.keys(touchedFields).length) {
      saveLocal(watch(), name + "_history");
    }
  }, [watch(), JSON.stringify(touchedFields)]);*/

  // console.log(getValues(),processDateSpecificFields(getValues()));

  const [initialFormValues, setInitialFormValues] = useState(getValues());
  //console.log(initialFormValues);

  useEffect(() => {
    setInitialFormValues(getValues());
  }, []);

  const formNew =
    defaults && Object.keys(defaults)?.filter((d: any) => defaults[d])?.length;
  // console.log(defaults, formNew);
  return (
    <div
      className={"editor" + (className ? " " + className : "")}
      style={{ padding: 0 }}
    >
      <FormDataContext.Provider
        value={{
          schema: schema,
          flatSchema: flatSchema,
          options: options,
          data: data,
          touchedMultiAdds: touchedMultiAdds,
          setTouchedMultiAdds: setTouchedMultiAdds,
          customFieldData: customFieldData,
          setCustomFieldData: setCustomFieldData,
          subUpdate: subUpdate,
          setSubUpdate: setSubUpdate,
          subTab: subTab,
          setSubTab: setSubTab,
          isSubmitted: isSubmitted,
          auth: auth,
          tempID: tempID,
          setTempID: setTempID,
        }}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(
              (data: any) => {
                if (
                  confirm &&
                  formNew !== 0 &&
                  Object.keys({ ...touchedFields, ...touchedMultiAdds }).length
                ) {
                  setModal2({
                    show: true,
                    heading: "Potwierdzenie zmian",
                    style: {
                      width: "900px",
                    },
                    content: (
                      <ConfirmChanges
                        methods={methods}
                        custom={{
                          touchedMultiAdds: touchedMultiAdds,
                          flatSchema: flatSchema,
                          data: data,
                          customData: customFieldData,
                        }}
                        originalValues={initialFormValues}
                        submit={submit}
                        sideEffects={sideEffects}
                        setApiError={setApiError}
                        options={options}
                        tempID={tempID}
                      />
                    ),
                  });
                } else {
                  save(data, submit, sideEffects, setApiError, tempID);
                }
              },
              (errors) => console.log(errors)
            )}
          >
            <Tabs
              schema={schema}
              displayedTab={displayedTab}
              setDisplayedTab={setDisplayedTab}
              settings={settings ? settings : undefined}
              history={history && history(options)}
            />

            <div className="bottom-bar">
              <div className="error-list">
                {/*<button
                  style={{ position: "absolute", bottom:"90%", left:"80%", zIndex:"3" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setModal2({
                      show: true,
                      content: (
                        <div style={{ fontSize: "13px", width:"100%" }}>
                          {Object.keys(getValues()).map((key: any) => (
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                              <div>{key}</div>
                              <div>{JSON.stringify(getValues()?.[key])}</div>
                            </div>
                          ))}
                        </div>
                      ),
                    });
                  }}
                >
                  Dane
                </button>*/}
                {apiError ? (
                  "Błąd API"
                ) : errors && errorKeys.length ? (
                  <>
                    <div className="error-list-head">
                      <div>Popraw</div>{" "}
                      <div className="error-list-head-number">
                        {errorKeys.length}
                      </div>
                    </div>
                    <ul>
                      {errorKeys.map((key: any) => (
                        <li
                          onClick={() => {
                            const tabContents = schemaKeys
                              .map((tab: any) =>
                                schema[tab].content.flat().map((tk: any) => {
                                  if (tk.subTabs) {
                                    const fields = tk.subTabs?.fields?.flat();
                                    return fields.map((f: any) => f.name);
                                  } else {
                                    return tk.name;
                                  }
                                })
                              )
                              .map((i: any) => i.flat());

                            tabContents.shift();
                            const tabToFocus = tabContents
                              .map((tab: any) =>
                                tab.includes(
                                  key
                                    .replace(/(%.*%)/, "")
                                    .replace(/(@.*@)/, "")
                                )
                              )
                              .indexOf(true);
                            // console.log(key, tabContents, tabToFocus);
                            setDisplayedTab(tabToFocus);
                            window.setTimeout(() => setFocus(key), 200);
                          }}
                        >
                          {errors[key]?.message?.toString()}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ display: "flex" }}>
                {/* <div>{tempID}</div> */}
                {showFunction ? (
                  <button
                    onClick={() => showFunction(false)}
                    className="button cancel"
                    type="button"
                  >
                    Anuluj
                  </button>
                ) : (
                  <></>
                )}
                <button className="button" type="submit">
                  Zapisz
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </FormDataContext.Provider>
    </div>
  );
}
