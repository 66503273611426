import { createContext } from "react";

type userData = {
  loggedIn: boolean;
  userID: number | undefined;
  username: string | undefined;
  userType: string | undefined;
  role: number | undefined;
  name: string | undefined;
  logOut: Function;
  onlineUsers: {
    first_name: string,
    last_name: string,
    last_online: string
  }[] | undefined;
  permissions: any;
  auth:any;
  godMode: Boolean | undefined;
  employeeID: any;
};

export const UserContext = createContext({
  loggedIn: false,
  username: undefined,
  name: undefined,
  role: undefined,
  userID: undefined,
  onlineUsers: undefined,
  permissions: undefined,
  auth: undefined,
  godMode: undefined,
  employeeID: undefined
} as userData);
