import { useState } from "react";
import { isJSONParsable, objectOrJSONparse } from "../functions/tools";

export default function DataViz({ data }: { data: Object }) {
  const keys = Object.keys(data);
  const [showKey, setShowKey] = useState();

  return (
    <div className="data-viz">
      <div>
        {keys?.map((k: any) => (
          <button
            style={{
              cursor: "pointer",
              background: k === showKey ? "lightgreen" : "initial",
            }}
            onClick={(e) => {
              e.preventDefault();
              setShowKey(k);
            }}
          >
            {k}
          </button>
        ))}
      </div>
      <div>
        {showKey &&
        typeof data[showKey] !== "string" &&
        typeof data[showKey] !== "number" &&
        typeof data[showKey] !== "undefined" &&
        data[showKey] !== null ? (
          <DataViz data={data[showKey]} />
        ) : (
          showKey && data[showKey]
        )}
      </div>
    </div>
  );
}
