import { useEffect, useState } from "react";
import { URL, URLProd } from "../paths";

export default async function insertGeneric(name: string, data: any) {
  let file = "";
  let fetchAddressFull = "";
  let URLString = URL;
  switch (name) {
    case "problem-types":
      file = "inserts/set-problem-types";
      break;
    case "employees":
      file = "inserts/set-employees";
      break;
    case "sales-documents":
      file = "inserts/set-sales-documents";
      break;
    case "reporting-forms":
      file = "inserts/set-reporting-forms";
      break;
    case "what-is-corrected":
      file = "inserts/set-what-is-corrected";
      break;
    case "correction-signed":
      file = "inserts/set-correction-signed";
      break;
    case "payment-form-change":
      file = "inserts/set-payment-form-change";
      break;
    case "discussion":
      file = "inserts/set-discussion";
      break;
    case "discussion-flag":
      file = "inserts/set-discussion-flag";
      break;
    case "online":
      file = "inserts/set-online";
      data = {
        ...data,
        focus: document.visibilityState === "hidden" ? "0" : "1",
      };
      break;
    case "add-user":
      file = "inserts/add-user";
      URLString = URLProd;
      break;
    case "modify-user":
      file = "inserts/modify-user";
      break;
    case "set-errors":
      file = "inserts/set-errors";
      break;
    case "set-error-correction":
      file = "inserts/set-error-correction";
      break;
    case "set-error-resolve":
      file = "inserts/set-error-resolve";
      break;
    case "set-error-urgent":
      file = "inserts/set-error-urgent";
      break;
    case "remove-error-correction":
      file = "inserts/remove-error-correction";
      break;
    case "transfer-receipts":
      file = "inserts/set-transfer-receipts";
      break;
    case "events":
      file = "inserts/set-events";
      break;
    case "venues":
      file = "inserts/set-venues";
      break;
    case "calendar-event":
      file = "inserts/set-calendar-events";
      break;
    case "calendar-event-potential":
      file = "inserts/set-calendar-events-potential";
      break;
    case "settle-calendar-event":
      file = "inserts/set-calendar-settlement";
      break;
    case "employee-groups":
      file = "inserts/set-employee-groups";
      break;
    case "set-attendance":
      file = "inserts/set-attendance-artificial";
      break;
    case "update-attendance-type":
      file = "inserts/update-attendance-type";
      break;
    case "holidays":
      file = "inserts/set-holidays";
      break;
    case "holiday-requests":
      file = "inserts/set-holiday-request";
      break;
    case "update-credentials-by-code":
      file = "inserts/register-activate-user";
      URLString = URLProd;
      break;
    case "permissions":
      file = "inserts/set-user-permissions";
      break;
    case "set-version":
      file = "inserts/set-version";
      break;
    case "meal-highlights":
      file = "inserts/set-meal-highlights";
      break;
    case "employee-contract":
      file = "inserts/set-contract";
      break;
    case "fill-log":
      file = "inserts/fill-log";
      break;
    case "set-employee-holiday-core":
      file = "inserts/set-employee-holiday-core";
      break;
    case "set-voucher-memory":
      file = "inserts/set-voucher-memory";
      break;
    case "set-voucher-register":
      file = "inserts/set-voucher-register";
      break;
    case "set-voucher-status":
      file = "inserts/set-voucher-status";
      break;
    case "set-voucher-status-individual":
      file = "inserts/set-voucher-status-individual";
      break;
    case "update-voucher-register":
      file = "inserts/update-voucher-register";
      break;
    case "update-meal-item":
      file = "inserts/update-meal-item";
      break;
    case "add-restaurant-menu-item":
      file = "inserts/add-meal-item";
      break;
    case "remove-restaurant-menu-item":
      file = "inserts/remove-meal-item";
      break;
    case "update-menu-order":
      file = "inserts/update-menu-order";
      break;
    case "remove-restaurant-menu-item":
      file = "inserts/remove-meal-item";
      break;
    case "remove-restaurant-menu-category":
      file = "inserts/remove-menu-category";
      break;
    case "update-menus-item":
      file = "inserts/update-menus-item";
      break;
    case "update-menu-categories":
      file = "inserts/update-menu-categories";
      break;
    case "add-menu":
      file = "inserts/add-menu";
      break;
    case "add-menu-category":
      file = "inserts/add-menu-category";
      break;
    case "update-menu-categories-order":
      file = "inserts/update-menu-categories-order";
      break;

    case "update-lsi-voucher-number":
      fetchAddressFull =
        // "https://data.agit.com.pl/hotelhub/backend/lsi/query_invoices.php";
        process.env.NODE_ENV === "production"
          ? "https://data.agit.com.pl/hotelhub/backend/lsi/query_add_vouchers.php"
          : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/query_add_vouchers.php";

      break;
  }

  const response = await fetch(
    fetchAddressFull || `${URLString}backend/${file}.php`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(data),
    }
  );

  return {
    response: await response.json(),
    ready: true,
  };
}
