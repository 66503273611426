export default function SubTab({
  subItem,
  t,
  propName,
  index,
  transform,
  setSubItem,
}: {
  subItem: any;
  t: any;
  propName: string;
  index: number;
  transform: Function;
  setSubItem: Function;
}) {
  return (
    <div
      className={subItem === t?.[propName] ? "active" : ""}
      onClick={() => setSubItem((prev: string) => t?.[propName])}
      data-key={t?.guid || index}
    >
      {transform(t?.[propName])}
    </div>
  );
}
