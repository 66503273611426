import EditableItem from "./EditableItem";
import Unit from "./Unit";

export default function MenuMeal({
  index,
  meal,
  setUpdates,
  order,
  setOrder,
  length,
  setOrderChanges,
  subset,
}: {
  index: number;
  meal: any;
  setUpdates: Function;
  order: number[];
  setOrder: Function;
  length: number;
  setOrderChanges: Function;
  subset: string;
}) {
  const {
    id,
    name_pl,
    info_pl,
    name_en,
    info_en,
    amount,
    unit,
    price,
    vegetarian,
    special,
  } = meal;
  return (
    <Unit
      classNames={subset === "drinks" ? "drinks-edit" : "meals-edit"}
      data={meal}
      setUpdates={setUpdates}
      index={index}
      order={order}
      setOrder={setOrder}
      setOrderChanges={setOrderChanges}
      length={length}
      clipBoard={(data: any) => {
        navigator.clipboard.writeText(
          data?.name_pl +
            "\t" +
            data?.info_pl +
            "\t" +
            data?.name_en +
            "\t" +
            data?.info_en +
            "\t" +
            data?.amount +
            " " +
            data?.unit +
            "\t" +
            data?.price +
            "\t" +
            (data?.vegetarian ? "wege" : "")
        );
      }}
      setActiveDB="menuUnit"
      deleteDB="remove-restaurant-menu-item"
    >
      {/* <EditableItem data={meal} name="id" locked={true} /> */}
      <EditableItem
        data={meal}
        name="name_pl"
        field="textarea"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="info_pl"
        field="textarea"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="name_en"
        field="textarea"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="info_en"
        field="textarea"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="amount"
        field="input"
        type="number"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="unit"
        field="input"
        updateDB="update-meal-item"
      />
      <EditableItem
        data={meal}
        name="price"
        field="input"
        type="number"
        updateDB="update-meal-item"
      />
      {subset === "drinks" ? (
        <EditableItem
          data={meal}
          name="price2"
          field="input"
          type="number"
          updateDB="update-meal-item"
        />
      ) : (
        <></>
      )}
      {subset === "drinks" ? (
        <EditableItem
          data={meal}
          name="alcohol_content"
          field="input"
          type="number"
          updateDB="update-meal-item"
        />
      ) : (
        <EditableItem
          data={meal}
          name="vegetarian"
          field="input"
          type="number"
          updateDB="update-meal-item"
        />
      )}
      <EditableItem
        data={meal}
        name="special"
        field="input"
        type="number"
        updateDB="update-meal-item"
      />
    </Unit>
  );
}
