import { useEffect, useState } from "react";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import Editables from "./Editables";
import MenuMeal from "./MenuMeal";
import MenuMealCategory from "./MenuMealCategory";

export default function MenuMeals({ subset }: { subset: string }) {
  const [updates, setUpdates] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState();

  const { loading: menusLoading, rows: menusRows } = useGeneric({
    name: "restaurant-menus",
  });
  const { loading, rows } = useGeneric({
    name: selectedMenu ? "restaurant-menu-single" : "",
    update: updates,
    order: {
      by: ["`order`", "id"],
      dir: ["ASC", "ASC"],
    },
    query: {
      menu_id: selectedMenu,
      drink: subset === "drinks" ? 1 : 0
    },
  });
  const { loading: catLoading, rows: catRows } = useGeneric({
    name: "restaurant-menu-categories",
    info: subset,
    update: subset,
    updateWithLoading:true
  });

  useEffect(() => {
    if (!menusLoading && menusRows && !selectedMenu) {
      setSelectedMenu(menusRows?.find((m: any) => m.current)?.id);
      setUpdates((prev: number) => prev + 1);
    }
    console.log("selected-menu");
  }, [menusLoading]);

  return (
    <Editables>
      <div className="menus-select">
        {/* <div>{updates}</div> */}
        {!menusLoading &&
          menusRows?.map((menu: any) => (
            <button
              className={
                "normal-button" + (menu?.id === selectedMenu ? " active" : "")
              }
              style={{ padding: "12px 32px" }}
              key={menu?.id}
              onClick={() => {
                setSelectedMenu(menu?.id);
                setUpdates((prev: number) => prev + 1);
              }}
            >
              {menu?.name}
            </button>
          ))}
      </div>
      <div style={{ display: "flex", flexWrap:"wrap", fontSize: "14px" }}>
        {catRows?.map((c: any) => (
          <a style={{ color: "black" }} href={"#" + c?.id}>
            <div style={{ padding: "6px 7px" }}>{c?.name_pl}</div>
          </a>
        ))}
      </div>
      {!loading && !catLoading && catRows && rows ? (
        catRows?.map((cat: any) => (
          <>
            <MenuMealCategory
              key={cat?.id}
              category={cat}
              data={rows?.filter((m: any) => m?.category === cat?.id)}
              updates={updates}
              setUpdates={setUpdates}
              menu_id={selectedMenu}
              subset={subset}
            />
          </>
        ))
      ) : (
        <Loading />
      )}{" "}
    </Editables>
  );
}
