import { formField, forms } from "../forms_new/formContentType";
import minutesToJSX, { minutesToString } from "../functions/minutesToJSX";
import prettyDate, {
  compareDay,
  dayDiff,
  isDate,
  prettyDateRange,
} from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";
import {
  arrayToNumbersAndStrings,
  cloneField,
  compareTimeStrings,
  createGUID,
  flatOptions,
  inflect,
  isJSONParsable,
  objectOrJSONparse,
  timeStringToNumber,
  timeStringToTime,
} from "../functions/tools";
import GenericDataView from "./GenericDataView";
import {
  dish_type,
  lsi_restaurant,
  schedule_item_type,
  meal_type,
  miscellanea,
  music_type,
  special_options,
  pickDatedFormValues,
  key_info_types,
  scheduleCurrent,
  yesOrNo,
} from "./definitions/common";
import UserName from "./UserName";
import InvoiceSelector from "./InvoiceSelector";
import ReceiptDetails from "./ReceiptDetails";
import eventIcon from "../../images/icons/star.svg";
import venueIcon from "../../images/icons/location.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import personIcon from "../../images/icons/people.svg";
import { sumCellValues } from "./definitions/calculables";
import SumSettlement from "./SumSettlement";
import { diffPart } from "./definitions/functions";
import { weddingForm } from "./addEventFormWedding";

function venueInput(data: any): formField {
  return {
    name: "venue",
    initialValue: data ? data.venue : "",
    label: "Główna sala",
    format: "half-2",
    element: "select",
    getOptionsFrom: "venues",
    // required: "Wybierz salę",
    // outerStyle: { display: "none" },
  };
}

function eventType(data: any): formField {
  return {
    name: "event_type",
    initialValue: data ? data.event_type : "",
    label: "Rodzaj imprezy",
    element: "select",
    // format: "half-1",
    getOptionsFrom: "events",
    required: "Wybierz rodzaj imprezy",
  };
}

function isSingleDay(vs: any) {
  return (
    !vs.date_end || !isDate(new Date(vs.date_end)) || vs.date_end === vs.date
  );
}

function times(data: any): formField[] {
  return [
    {
      name: "time_start",
      format: "half-1",
      initialValue: data ? data.time_start : "",
      label: "Od",
      inputType: "time",
      visibleIf: (vs: any) => isSingleDay(vs),
      required: "Podaj o której godzinie zaczyna się impreza",
    },
    {
      name: "time_end",
      format: "half-2",
      initialValue: data ? data.time_end : "",
      label: "Do",
      inputType: "time",
      visibleIf: (vs: any) => isSingleDay(vs),
      required: "Podaj do której godziny trwa impreza",
      /*  validate: (v: string, vs: any) => {
        const t1 = parseInt(vs.time_start.replace(":", ""));
        const t2 = parseInt(vs.time_end.replace(":", ""));
        //console.log(t1,t2);
        return (
          t1 < t2 ||
          "Godzina zakończenia jest wcześniejsza niż godzina rozpoczęcia"
        );
      },*/
    },
    /*
    {
      name: "duration",
      label: "Czas trwania",
      from: {
        values: ["time_start", "time_end"],
        transform: (values) => {
          const a = new Date("2000-01-01 " + values.time_start);
          const b = new Date("2000-01-01 " + values.time_end);
          if (a.getTime() > b.getTime()) {
            b.setDate(b.getDate() + 1);
          }
          return minutesToJSX((b.getTime() - a.getTime()) / 1000 / 60, false);
        },
      },
    },*/
  ];
}



function eventDate(data: any, date: any, name = "date"): formField {
  return {
    name: name,
    initialValue: data
      ? data[name]
      : date
      ? new Date(date).toISOString().slice(0, 10)
      : "",
    label: "Data",
    inputType: "date",
    required: "Podaj datę imprezy",
    min: {
      value: "2015-01-01",
      message: "Data wydarzenia zbyt odległa w czasie",
    },
    max: {
      value: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
        .toISOString()
        .slice(0, 10),
      message: "Możesz planować wydarzenia tylko na 5 lat w przyszłość",
    },
  };
}

function showDates(values: any) {
  const dates = values.dates ? JSON.parse(values.dates) : [];
  const firstDate = dates.length && dates[0].date;
  const lastDate = dates.length && dates.at(-1).date;

  return (
    dates && (
      <>
        {firstDate != lastDate ? (
          <>
            {prettyDateRange(firstDate, lastDate)}
            {/*
              <div style={{ fontSize: "15px" }}>
                {prettyDate(firstDate, "", true)} -{" "}
                {prettyDate(lastDate, "", true)}
              </div>{" "}
              <div style={{ color: "grey", fontSize: "11px" }}>
                {inflect(dates.length, ["dni", "dzień", "dni", "dni"])}
          </div>*/}
          </>
        ) : (
          <>{prettyDate(firstDate, "Dziś", true)}</>
        )}
      </>
    )
  );
}

export const addEventFormPotential = (
  data: any,
  date?: Date,
  auth?: any
): forms => ({
  basics: {
    name: "Informacje wstępne",
    hide: !auth?.basic,
    readOnly: auth?.basic != 2,
    className: "basic-columns",
    content: [
      [
        {
          name: "is_company",
          initialValue: data ? (data.is_company == "1" ? 1 : 0) : 0,
          label: "Rodzaj klienta",
          format: "half-1",
          element: "select-picker",
          optionsList: [
            {
              id: 0,
              name: "indywidualny",
              active: true,
            },
            {
              id: 1,
              name: "firma",
              active: false,
            },
            {
              id: 2,
              name: "własne",
              active: false,
            },
          ],
          required: "Wskaż rodzaj klienta",
        },
        {
          name: "company_nip",
          initialValue: data ? data.company_nip : "",
          label: "NIP",
          format: "half-2",
          minLength: {
            value: 10,
            message: "Numer NIP jest za krótki",
          },
          maxLength: {
            value: 10,
            message: "Numer NIP jest za długi",
          },

          validate: (nip: any) => {
            if (nip === "") return true;
            if (typeof nip !== "string") return false;

            nip = nip.replace(/[\ \-]/gi, "");

            let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(nip.substring(9, 10));
            let weightCount = weight.length;
            for (let i = 0; i < weightCount; i++) {
              sum += parseInt(nip.substr(i, 1)) * weight[i];
            }

            return sum % 11 === controlNumber || "Nieprawidłowy numer NIP";
          },
          visibleIf: { is_company: "1" },
          element: "hybrid",
          db: "nip",
          query: (input: string | number) => ({
            nip: input,
          }),
          resultElement: (item: any) => <div>{item.name}</div>,
          resultUpdate: (item: any) => [
            ["company_name", item.name],
            ["company_details", JSON.stringify(item)],
          ],
          inputType: "search",
        },
        {
          name: "company_name",
          initialValue: data ? data.company_name : "",
          label: "Nazwa firmy",
          element: "textarea",
          rows: 2,
          style: { height: "60px", lineHeight: "105%" },
          visibleIf: { is_company: "1" },
          required: "Podaj nazwę firmy",
        },
        {
          name: "contact_person_heading",
          element: false,
          description: (
            <div className="little-description-highlight">
              Osoba do kontaktu:
            </div>
          ),
          visibleIf: { is_company: "1" },
        },
        {
          name: "first_name",
          initialValue: data ? data.first_name : "",
          label: "Imię",
          format: "half-1",
          //   required: "Podaj imię klienta/osoby do kontaktu",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "last_name",
          initialValue: data ? data.last_name : "",
          label: "Nazwisko",
          format: "half-2",
          required: "Podaj nazwisko klienta/osoby do kontaktu",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "email",
          initialValue: data ? data.email : "",
          label: "E-mail",
          format: "half-1",
          type: "email",
          //   required: "Podaj e-mail klienta",
          pattern: {
            value:
              /(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/,
            message: "Niewłaściwy format adresu e-mail",
          },
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "phone",
          initialValue: data ? data.phone : "",
          label: "Telefon",
          format: "half-2",
          //   required: "Podaj telefon klienta",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
      ],
      [
        {
          name: "id",
          initialValue: data ? data.id : "",
          inputType: "hidden",
        },
        eventType(data),
        venueInput(data),
        /*{
          ...eventDate(data, date),
          label: "Data od",
          format: "half-1",
          outerStyle: { display: "none" },
        },*/
        /* {
          ...eventDate(data, date, "date_end"),
          label: "Data do",
          format: "half-2", 
          validate: (v: any, values: any) => {
            const dateStart = new Date(values.date);
            const dateEnd = new Date(values.date_end);
            const monthStart = dateStart.getMonth();
            const yearStart = dateStart.getFullYear();
            const monthEnd = dateEnd.getMonth();
            const yearEnd = dateEnd.getFullYear();
            return (
              (dateEnd >= dateStart &&
                (yearStart === yearEnd
                  ? monthEnd - monthStart < 2
                  : monthStart === 11 && monthEnd === 0)) ||
              "Niedozwolone daty rozpoczęcia i zakończenia"
            );
          },
        },*/
        /*...times(data),*/

        {
          name: "dates",
          //fullSpan: true,
          //visibleIf: (vs: any) => !isSingleDay(vs),
          disabled: !auth?.agenda,
          label: "Daty",
          element: "multiAdd",
          className: "days",
          multiAddProps: {
            //mini: true,
            unique: ["date"],
            changeEffect: (
              form: any,
              loaded: any,
              prevRowValues: any,
              rowValues: any,
              setValue: Function
            ) => {
              const schedules =
                form && form?.schedules
                  ? JSON.parse(form.schedules)
                  : loaded && loaded.schedules && JSON.parse(loaded.schedules);
              const newDating = schedules?.map((o: any) => ({
                ...o,
                date: o.date === prevRowValues?.date ? rowValues?.date : o.date,
              }));
              setValue("schedules", JSON.stringify(newDating));
              const dateRegex = new RegExp(`%${prevRowValues?.date}%`);
              Object.keys(form).forEach((key: string) => {
                const isDateSpecific = key.match(dateRegex);
                if (isDateSpecific) {
                  const oldValue = form[key];
                  delete form[key];
                  const newKey =
                    key.replace(/%.*%/, "") + "%" + rowValues?.date + "%";
                  setValue(newKey, oldValue);
                }
              });
            },
            maxLength: 7,
            reorderable: false,
            sort: {
              cell: "date",
              callBack: (a: any, b: any) => dayDiff(b.date, a.date),
            },
            foldable: false,
            clear: false,
            add: [
              (entries: any) => {
                const nextDate = new Date(entries.at(-1).date);

                nextDate.setDate(nextDate.getDate() + 1);
                // console.log(nextDate);
                return {
                  date: nextDate.toISOString().slice(0, 10),
                  guid: createGUID(),
                };
              },
              "DODAJ DZIEŃ",
            ],
            affects: (values: any) => {
              const a = new Date("2000-01-01 " + values.time_from);
              const b = new Date("2000-01-01 " + values.time_to);
              if (a.getTime() > b.getTime()) {
                b.setDate(b.getDate() + 1);
              }
              return {
                time_sum: minutesToString(
                  (b.getTime() - a.getTime()) / 1000 / 60,
                  false
                ),
              };
            },
            itemString: ["DNI", "DZIEŃ", "DNI", "DNI"],
            multiEntries: (v: any) => [
              {
                date: date ? new Date(date).toISOString().slice(0, 10) : "",
                //time_from: v.time_start,
                //   time_to: v.time_end,
              },
            ],
            /*const rows = [];
              const n = 0;//dayDiff(v.date, v.date_end);
              let date  = new Date(v.date);
              for ( let p = 0; p <= n; p++) {
                const curDate = new Date(date);
                curDate.setDate(date.getDate() + p);
                rows.push({
                  date: curDate.toISOString().slice(0,10)
                });
              }
              return rows;
            },*/

            columns: {
              /*number: {
                name: "Lp",
                index: true,
              },*/
              date: {
                name: "Dzień",
                type: "date",
                inputStyle: {
                  //   width: "112px",
                  fontSize: "15px",
                  padding: "0 6px",
                  textAlign: "left",
                  background: "linear-gradient(10deg, #94c4d1, #c1dfe6)",
                  boxShadow: "0 2px rgba(0,0,0,.2)",
                },
                style: {
                  verticalAlign: "baseline",
                  width: "115px",
                },
                //disabled: true,
              },

              // time_from: {
              //   name: "Od",
              //   type: "time",
              //   step: 60,
              //   inputStyle: {
              //     fontSize: "15px",
              //   },
              // },
              // time_to: {
              //   name: "Do",
              //   type: "time",
              //   step: 60,
              //   inputStyle: {
              //     fontSize: "15px",
              //   },
              // },
              // time_sum: {
              //   name: "Czas",
              //   disabled: true,
              //   inputStyle: {
              //     fontSize: "11px",
              //   },
              // },
            },
          },
        },
      ],
      {
        blockStyle: {
          order: 1,
          gridColumn: "1/4",
          gridTemplateRows: "1fr",
        },
        content: [
          {
            name: "comment",
            
            element: "textarea",
            style: {
              height: "150px",
            },
            placeholder: "tutaj wpisz wstępne informacje o imprezie",
            // initialValue: data ? data.key_info : ""
          },
        ],
      },
    ],
  },
});
